<template>
  <div class="tree-container"  ref="container">
  <div class="tree-view" ref="view">
    <div class="background-container"  ref="bg">
    <!--  @wheel.prevent="wheelHandler"  :style="initialTransformStyle"-->  
    <div 
        v-for="(node) of nodeDataList.filter(e=>e.data.regionLast)"
        class="background"
        :key="node.data.id"
        :style="{
          left: formatDimension(node.data.regionLeft),
          top: formatDimension(node.data.regionTop),
          width: formatDimension(node.data.regionWidth),
          height: formatDimension(node.data.regionHeight)
        }">
        <p class="h-80 w-full p-40 uppercase" style="display:block;border-radius:250px;padding-top:20px;color:#5c7c78;font-weight:bold;font-size:40px;pointer-events:all;">
            <button @click="collapseExpandAll(false,node.data.domain)" style="cursor:pointer;font-size: 68px;top: 18px;position: relative;padding-right: 20px;" class="material-icons-outlined" :style="{color:domainShowing[node.data.domain]?'#5c7c78':'red'}">{{domainShowing[node.data.domain]?'visibility':'visibility_off'}}</button>
            {{node.data.domain}}</p>
       </div>
       <div 
        v-for="(node) of nodeDataList.filter(e=>e.data.top)"
        class="background-tree"
        :data-domain="node.data.domain||''"
        :data-treeid="node.data.treeId"
        :key="'_'+node.data.id"
        :style="{
          left: formatDimension(node.data.left-config.nodeWidth/2),
          top: formatDimension(node.data.top+config.nodeHeight*1.5),
          width: formatDimension(node.data.width+config.nodeWidth*1),
          height: formatDimension(node.data.height+config.nodeHeight*1)
        }">
            <!--button @click="scaleToFit(node.data.treeId)"> <svg
              style="fill: rgb(255, 255, 255); stroke-width: 25px;"
              stroke="#000"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 500 500"
              class="full_screen transition duration-300 ease-in-out"
            >
              <rect x="2.366" y="1.853" width="496.524" height="496.117" />
              <line x1="429.634" y1="75.446" x2="285.835" y2="219.044" />
              <line x1="216.705" y1="290.124" x2="68.509" y2="433.491" />
              <line x1="429.483" y1="65.481" x2="429.483" y2="159.969" />
              <line x1="442.269" y1="75.466" x2="339.419" y2="75.466" />
              <line x1="158.238" y1="434.039" x2="55.388" y2="434.039" />
              <line x1="68.247" y1="351.187" x2="68.247" y2="445.674" />
            </svg></button-->
       </div>
    </div>
    <svg class="svg vue-tree" ref="svg" ></svg>

    <div
      class="dom-container" id="nodes"
      ref="domContainer"
      
    ><!-- create heap with before after to heap children -->
      <transition-group name="tree-node-item" tag="div"><!-- @click="onClickNode(index)" -->
        <div
          :class="node.data.isTopOrBottom!=null?'node-slot top':'node-slot'"
          :data-domain="node.data.isTopOrBottom!=null?node.data.domain:''"
          :data-treeid="node.data.treeId"
          v-for="(node,index) of nodeDataList"
          :index="index"
          :id="node.data.id"
          :typeId="node.data.blocktype"
          :key="node.data.id" 
          :style="{
            left: formatDimension(
              direction === DIRECTION.VERTICAL ? node.data.x||node.x : node.y
            ),
            top: formatDimension(
              direction === DIRECTION.VERTICAL ? node.y : node.data.x||node.x
            ),
            width: formatDimension(config.nodeWidth),
            height: formatDimension(config.nodeHeight)
          }"
        >
          <slot
            name="node"
            :node="node.data"
            :collapsed="node.data._collapsed"
            :index="index"
            :treeId="node.data.treeId"
            :collapsable="node.data.children&&node.data.children.length>0||node.data._children&&node.data._children.length>0"
            :collapse="onClickNode"
            :setArrangement="setArrangement"
            :wheelHandler="wheelHandler"
            :regionLeft="node.data.regionLeft"
            :regionTop="node.data.regionTop"
            :regionWidth="node.data.regionWidth"
            :regionHeight="node.data.regionHeight"
          >
            <!-- 默认展示value字段 -->
            <span>{{ node.data.name }}</span>
          </slot>
        </div>
      </transition-group>
    </div>
  </div>
  </div>
</template>

<script>
document.addEventListener('gesturestart', (e) => e.preventDefault())
document.addEventListener('gesturechange', (e) => e.preventDefault())


import * as d3 from 'd3'
import {Node} from "d3-hierarchy/src/hierarchy/index";
import { EventBus } from "@/modules/event_bus";
import { globals } from "@/modules/globals";

const MATCH_TRANSLATE_REGEX = /translate\((-?[\d.]+)px, ?(-?[\d.]+)px\)/i
const MATCH_SCALE_REGEX = /scale\((\S*)\)/i

const LinkStyle = {
  CURVE: 'curve',
  STRAIGHT: 'straight'
}

const DIRECTION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
}

const DEFAULT_NODE_WIDTH = 100
const DEFAULT_NODE_HEIGHT = 100
const DEFAULT_LEVEL_HEIGHT = 200

const ANIMATION_DURATION = 800
/* function uuid() {
  const s = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4'
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
  s[8] = s[13] = s[18] = s[23] = '-'
  return s.join('')
} */

function rotatePoint({ x, y }) {
  return {
    x: y,
    y: x
  }
}

export default {
  name: 'Tree',
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          nodeWidth: DEFAULT_NODE_WIDTH,
          nodeHeight: DEFAULT_NODE_HEIGHT,
          levelHeight: DEFAULT_LEVEL_HEIGHT,
          paddingAbove: 80,
          treeOffset: 0.5 * DEFAULT_NODE_WIDTH,
          stackLimit: 5,
          innerLimit: 3,
          startArrangement: 'horizontal',
          deeperArrangement: 'left'
        }
      }
    },
    linkStyle: {
      type: String,
      default: LinkStyle.CURVE
    },
    direction: {
      type: String,
      default: DIRECTION.VERTICAL
    },
    collapseEnabled: {
      type: Boolean,
      default: true
    },
    dataset: {
      required: true
    }
  },
  data() {
    return {
      domainShowing:{
        regional: true,
        landelijk: true
      },
      maxHeight: -Infinity,
      leftSide: Infinity,
      rightSide: -Infinity,
      scale:1,
      zoomCount: 0,
      d3,
      int:null,
      colors: '568FE1',
      nodeDataList: [],
      linkDataList: [],
      storedPoints: [],
      initTransformX: window.outerWidth/2,
      initTransformY: 0,
      DIRECTION,
      mouseX:0,
      mouseY:0,
      regionNames:["Regionaal","Landelijk"],
    }
  },
  computed: {
    /*initialTransformStyle() {
      return {
        transform: `scale(${this.scale}) translate(${this.initTransformX}px, ${this.initTransformY}px) translateZ(0)`,
        transformOrigin: 'center center 0'
      }
    }*/
  },
  created() {
    EventBus.$on('unhide_domain', (e) => {
      this.collapseExpandAll(true,e);
    })
    /*
    this.$refs.container.onauxclick = (e)=> {
      console.log(e.button);
      e.preventDefault();
    };
    */
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.int = setInterval(()=>{
        if(this.dataset!=null){
          clearInterval(this.int);
          this.draw()
          this.draw(true)
          //this.initTransform()
          this.enableDrag()
          this.collapseExpandAll(false,'regionaal')
          this.collapseExpandAll(false,'landelijk')
          setTimeout(()=>{
          this.collapseExpandAll(false,'landelijk')
          this.collapseExpandAll(false,'regionaal')
          },200);
      }},500)
      
    },
    collapseExpandAll(expand=false,domain=null){
      // first click all topnodes to collapse/expand or click all bottom nodes their expand buttons
      [].slice.call(
        domain!=null?document.querySelectorAll('.top[data-domain="'+domain+'"] #expand'+(document.querySelector('.background-tree[data-domain="'+domain+'"]').classList.contains('notvisible')?':not(.expanded)':'.expanded')):
        document.querySelectorAll((!expand?'.top .rich-media-node':'.rich-media-node')+':not(.notvisible) #expand'+(expand?':not(.expanded)':'.expanded'))
      ).forEach(e=>{if(e) e.click();});
      if(domain!=null){
        // next if its a hide action, toggle the hidden class and hide/unhide the interlinks
        //setTimeout(()=>{
          [].slice.call(document.querySelectorAll('.background-tree[data-domain="'+domain+'"]')).forEach(el=>el.classList.toggle('notvisible'));
          [].slice.call(document.querySelectorAll('.top[data-domain="'+domain+'"] .rich-media-node')).forEach(el=>el.classList.toggle('notvisible'));
          [].slice.call(document.querySelectorAll('.interlink.region-'+['regionaal','landelijk'].indexOf(domain))).forEach(el=>el.classList[(document.querySelector('.top[data-domain="'+domain+'"] .rich-media-node').classList.contains('notvisible')?'add':!this.hideInterlinks?'remove':'add')]('notvisible'));
          this.domainShowing['regionaal'] = !document.querySelector('.background-tree[data-domain="regionaal"]').classList.contains('notvisible');
          this.domainShowing['landelijk'] = !document.querySelector('.background-tree[data-domain="landelijk"]').classList.contains('notvisible');

        //},20);
      }
    },
    wheelHandler(event) {
      if (event.ctrlKey) {
          return;
      }
      event.preventDefault();
      if(event.deltaY!=0){
        this.zoom(event.deltaY<0?true:false,event);
      }
    },
    mouseTracker(event){
      this.mouseX=event.x;
      this.mouseY=event.y;
    },
    toggleInterlinks(){
      this.hideInterlinks = !this.hideInterlinks;
      if(!this.hideInterlinks) [].slice.call(document.querySelectorAll('.interlink')).forEach(el=>el.classList.remove('hidden'));
      document.getElementsByClassName('vue-tree')[0].classList[this.hideInterlinks?'add':'remove']('hideinterlinks');
    },
    restoreScale() {
      this.zoom()
    },
    scaleToFit(treeId){
      globals.selectedId = treeId;
      let scaleToTree = globals.lastTreeClicked==null?true:globals.lastTreeClicked==treeId?false:true; 
      EventBus.$emit("ui_click", 'scale_to_fit'+(scaleToTree?'_tree':''));
      globals.lastTreeClicked = scaleToTree?treeId:null;
    },
    zoom(zoomIn=true,event=null,checkViewport=true) {
      if(document.getElementsByClassName('tree-view')[0].classList.contains('resizing')) return null;

      // get all previous values for zoom
      let pos = this.getTranslate();
      const originTransformStr = this.$refs.view.style.transform
      const scaleMatchResult = originTransformStr.match(MATCH_SCALE_REGEX)
      const originScale = parseFloat(scaleMatchResult[1])
      let targetScale = 0;
      let prevOrigin = this.$refs.view.style.transformOrigin.split(' ').map((or,i)=>parseFloat(or=='center'?this.$refs.container['client'+(i==0?'Width':'Height')]/2:or.split('px')[0]));
      
      // calculate the new zoom
      if(event){
        targetScale = Math.pow(originScale,event.deltaY<0?1.2:0.8/* 1 - event.deltaY * 0.0005 */)+(event.deltaY<0?0.2:-0.2);
        if(globals.smartZoom){
          // set a resetter that resets after a amount of time
          if(this.int==null) this.int = setTimeout(()=>{this.zoomCount = 0;clearTimeout(this.int);this.int=null},1000)
          // if zoom out then do some things
          if(targetScale<=originScale) {
            this.zoomCount ++;
            // if > 3 zoom out actions in the 1 sec of time do a scale to fit
             if(this.zoomCount>2){
               EventBus.$emit("ui_click", 'scale_to_fit');
               this.zoomCount = -2;
               return null;
             }
            
          }else this.zoomCount = 0; // reset zoom count when zooming in again
        }
        
      }else targetScale = Math.pow(originScale,zoomIn?1.2:0.8)+(zoomIn?0.1:-0.1);

      // limit the amount of zoom
      if(targetScale<globals.minScale) targetScale = globals.minScale;
      else if(targetScale>2.5) targetScale = 2.5;
      
      globals.lastTreeClicked = null;
      
      // apply the zoom
      if(event) this.$refs.view.style.transformOrigin =/*  (prevOrigin[0] - */( /* +  document.querySelector('.tree-view').clientWidth/2- */event.clientX /* - this.$refs.view.getBoundingClientRect().left */)/* /originScale */ + "px " + /* (prevOrigin[1] - */( /* +  document.querySelector('.tree-view').clientHeight/2- */event.clientY /* - this.$refs.view.getBoundingClientRect().top */)/* /originScale */ /*) ((scaleNum-this.currentScale)/this.currentScale+1)*/+"px"
      let translateString = `translate(${(pos.x - (event?(event.clientX-prevOrigin[0])/originScale*0.9:0))*(originScale>targetScale?originScale:1)}px, ${pos.y - (event?(event.clientY-prevOrigin[1])/originScale*0.9:0)}px) translateZ(0)`
      this.$refs.view.style.transform = `scale(${targetScale}) ` + translateString;
      
      // check if the container gets out of bounds
      if((targetScale<0.6||targetScale>2.4)&&globals.smartZoom&&checkViewport&&!this.elementIsInViewport(this.$refs.view,true, targetScale))  EventBus.$emit("ui_click", 'scale_to_fit');
    },
    elementIsInViewport(element,fullScreenSafe=false,scale=1){
      const rect = element.getBoundingClientRect();
      let offset = 100;
      return (
          rect.top >= 0-(fullScreenSafe?(window.innerHeight || document.documentElement.clientHeight)*0.8/scale:offset) &&
          rect.left >= 0-(fullScreenSafe?(window.innerHeight || document.documentElement.clientHeight)*0.8/scale:offset) &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)*(fullScreenSafe?1.9/scale:0.9) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)*(fullScreenSafe?1.9/scale:0.9)
      );
    },
    getCenter(){
      let element = document.getElementById('nodes');
      return {x:element.clientX+element.width/2,y: element.clientY+element.height/2};
    },
    getTranslate() {
      let string = this.$refs.view.style.transform
      let match = string.match(MATCH_TRANSLATE_REGEX)
      if (match === null) {
        return [null, null]
      }
      let x = parseInt(match[1])
      let y = parseInt(match[2])
      return {x:x, y:y}
    },
    isVertical() {
      return this.direction === DIRECTION.VERTICAL
    },
    /* addUniqueKey(rootNode) {
      const queue = [rootNode]
      while (queue.length !== 0) {
        const node = queue.pop()
        node.id = uuid();
        node._name = node.name;
        if (node.children) {
          queue.push(...node.children)
        }
      }
      return rootNode
    }, */
    getBoundingBox(parent,data=[]){ 
      let minx = data[0]||Infinity,miny = data[2]||Infinity;
      let maxx = data[1]||-Infinity,maxy = data[3]||-Infinity;
      function loopChilds(children){
        for(let child of children){
          if(child.x<minx) minx = child.x;
          if(child.x>maxx) maxx = child.x;
          if(child.y<miny) miny = child.y;
          if(child.y>maxy) maxy = child.y;
          if(child.children) loopChilds(child.children);
        }
      }
      loopChilds(parent.children?parent.children:[parent]);
      return [minx-this.config.nodeWidth/2,maxx+this.config.nodeWidth/2,miny-this.config.nodeHeight/2,maxy+this.config.nodeHeight/2];
    },
    initTransform() {
      this.scale = 1//;0.05;//* Math.min(this.$refs.container.offsetHeight/this.maxHeight,this.$refs.container.offsetWidth/(this.rightSide-this.leftSide))
      const containerWidth = this.$refs.container.offsetWidth
      const containerHeight = this.$refs.container.offsetHeight
      if (this.isVertical()) {
        this.initTransformX = Math.floor(containerWidth / 2)
        this.initTransformY = 0;//Math.floor(-6*this.config.nodeHeight)
      } else {
        this.initTransformX = Math.floor(this.config.nodeWidth)
        this.initTransformY = Math.floor(containerHeight / 2)
      }
    },
    // get the amount of steps bwlow the next child has to be drawn by looking at all subchildren
    calculateNeededSpace(node,parentArrangement,deepArrange=false){
      let offsets = {
        bottom: 0,
        right: 0,
        left: 0,
        totalWidth:  0//(node.data&&node.data.arrangement||this.config[(deepArrange?'deeper':'start')+'Arrangement'])=='horizontal'&&node.children.length>1?this.config.nodeWidth:0
      };
      const getDepth = ({ children,data },lastNode=false) =>{
       
        if (children){
          let arrangement = data&&data.arrangement||this.config[(deepArrange?'deeper':'start')+'Arrangement'];//console.log(arrangement,deepArrange)
          // add extra yoffset for horizontal each other placed children
          offsets['bottom'] += (children.length==1||arrangement.match(/right|left/)==null?1:data&&data.children&&data.children.length||children&&children.length||0);
          // add xtra x offset for left or right arrangement
          if(arrangement.match(/left|right|below/)){
            offsets[arrangement=='below'?'left':arrangement] += children.length>1?this.config.treeOffset:0;
            if(arrangement=='below'){
              offsets['right'] += children.length>1?this.config.treeOffset:0;
            }
            offsets['totalWidth'] += children.length>1&&(lastNode&&arrangement.match(/left|below/)||!lastNode&&arrangement.match(/right/))?this.config.treeOffset:0;
          }else offsets['totalWidth'] += children.length>1?this.config.nodeWidth/3.5:0;
        }
        
        return 0.5 + (children ? Math.max(...children.map((n,i)=>getDepth(n,i==children.length-1&&(n.data&&n.data.arrangement||this.config[(deepArrange?'deeper':'start')+'Arrangement'])=='horizontal')+n.data&&n.data.children&&n.data.children.length-1||0)) : 0)
      }
      let depth = getDepth(node);
      return [depth+offsets['bottom'],offsets['left'],offsets['right'],offsets['totalWidth']];
    },
    // rearrange children recursively to the new added arrangements (right/left/horizontal)
    arrangeChildren(parent,arrangeDeep=false,treeDirection=-1,multiChilds=false){
      let currentLoc = {x: parent.x, y: parent.y -(multiChilds?25:0)};
      let index = 0;
      
      
      
      // add back missing children that were removed in order to create a denser initial tree layout
      if(parent.data&&parent.data.__children){
        
        for(let child of parent.data.__children.slice((parent.children||parent.data.children||[]).length)){
          let children = child.children;
          child = new Node(child);
          child.y += 25;
          child.children = children;
          if(parent.children==null) parent.children = parent.data.children||[];
          parent.children.push(child);
          this.nodeDataList.push(child);
          
        }
      }
      let grandparentArrangement = parent.parent&&parent.parent.data&&(parent.parent.arrangement||parent.parent.data.arrangement)||"";
      let arrangement = parent.data&&parent.data.arrangement=='below'?parent.data.arrangement:parent.children&&parent.children.length>2?parent.data.children[0].blocktype=='5'||parent.data.children[0].blocktype=='2'?'horizontal':'left':parent.data&&parent.data.arrangement||arrangeDeep?this.config.deeperArrangement:this.config.startArrangement;
      if(!arrangeDeep&&parent.data&&parent.data.children&&parent.data.children.length>2&&parent.data.children[0].blocktype!='5'){
        arrangeDeep = true;
      }
      if(arrangeDeep)  parent.data.arrangement = parent.data.arrangement || this.config.deeperArrangement;
      //if(window.isNaN(currentLoc.y)) parent.y = currentLoc.y = parent.parent.y + this.config.levelHeight;
      
      // add some extra padding above the parents children
      // to horizontal children some paddingAbove
      if(arrangement=="horizontal") currentLoc.y += this.config.paddingAbove*1.6;
      else if(arrangement=="below") currentLoc.y += this.config.paddingAbove*0.4;
      // to left/right arrangement padding horizontal
      else  currentLoc.y += this.config.levelHeight - this.config.paddingAbove/1.65

      // track amount of x direction padding added 
      let xOffsetChange = 0;
      
      // loop the children
      if(parent.children){
        let belowArrangement = arrangement=='below'?'left':null;
        
        for (let child of parent.children){          

         // if the child is not a d3 type node make it to avoid naming differences (can be possibly removed if all is running well)
        if(child.data==null){
          let children = child.children;
          child = new Node(child);
          child.children = children;
          //this.linkDataList.push({source:parent,target:child});
          parent.children[index] = child;
        }
        
        // set the lbelow arrangement to switch left-right and vice versa
        if(belowArrangement){
          arrangement = belowArrangement;
          belowArrangement = arrangement =='left'?'right':'left';
          
        }
        
        // add the link from parent to child
        this.linkDataList.push({source:parent,target:child,type:arrangement});
        
        // if some data is missing fix it (can be possibly removed if all is running well) 
        if(child.parent==null||typeof(child.parent)=='number') child.parent = parent;
        if(!child.y) child.y = parent.y + this.config.paddingAbove;
        if(!currentLoc.x) currentLoc.x = parent.x;
        
        // change the childs x and y coords according to arrangement
        [ child.x, child.y] = [currentLoc.x,currentLoc.y] = 
                  // if a childhorizontal is set and this is the last child
                  parent.data&&parent.data.centrelast&&index ==parent.children.length-1? [parent.x, currentLoc.y + this.config.paddingAbove]:
                  // if the arrangement is horizontal or there is only 1 child
                  arrangement=='horizontal'||parent.children.length==1?/* (child.x!=null? */[parent.children.length==1&&grandparentArrangement=="horizontal"||parent.data.id==999999?child.x:index==0?currentLoc.x - (parent.children.length-1)/2 * (this.config.nodeWidth+this.config.paddingRight):currentLoc.x+this.config.nodeWidth + this.config.paddingRight,parent.children.length==1&&grandparentArrangement=="horizontal"||arrangement=='horizontal'||parent.data.id==999999?multiChilds?currentLoc.y:child.y+25:currentLoc.y+(parent.children.length==1?150:100)]/* :[child.x<currentLoc.x?child.x:currentLoc.x+(index>0?this.config.paddingRight+this.config.nodeWidth:0), child.y<currentLoc.y?child.y:currentLoc.y] */:
                  // if the arrangement is left or right
                  [parent.x + (arrangement=='left'?-1:1) * this.config.treeOffset, currentLoc.y + (belowArrangement=='left'?0:this.config.paddingAbove)];

        // add some extra space above solo children
        //if(parent.children.length==1 && parent.data.blocktype=='5' && child.data.blocktype == '3') child.y += this.config.paddingAbove/3;
    
        // if for some reason some childs are not bound correctly in the tree, try to find the node in the list and set it directly
        let childNode = this.nodeDataList.find(node=>node.data.id==child.data.id);
        if(childNode) [childNode.x, childNode.y] = [ child.x, child.y];
        else this.nodeDataList.push(child);
        
        // if there are children of the child, rearrange the children using this function itself
        if(child.children){
          

          // to fix the inbetween space to the next tree branch, add the needed space to the x or y
          
          // always check its own children and get the required x and y offset change
          let ownDepth = this.calculateNeededSpace(child,grandparentArrangement,arrangeDeep);
          // if the parent is arranged horizontal and the next child is arranged left it will also need to check that next child
           let nextDepth = null;
          if(arrangement=='horizontal'&&parent.children[index+1]&&parent.children[index+1].children&&parent.children[index+1].children.length>0){
            // check how deep the childs children go to decide extra offset for next child
            nextDepth = this.calculateNeededSpace(parent.children[index+1],grandparentArrangement,arrangeDeep);
          } 
          // add the extra spacing
          if(arrangement=="horizontal"){
             currentLoc.x += ownDepth[2] + (nextDepth?nextDepth[1]:0)+(nextDepth&&nextDepth[0]>1?ownDepth[3]*1.3:0)
            // add the offset to a cumulative that is used to center the whole branch later
            xOffsetChange += ownDepth[2] + (nextDepth?nextDepth[1]:0)+(nextDepth&&nextDepth[0]>1?ownDepth[3]:0)
            
            //if(index==parent.children.length-1) xOffsetChange = ownDepth[3];
          }else{
            currentLoc.y += ownDepth[0] * this.config.paddingAbove;
          }
          
          // decide on the required interlink direction based on its location in tree (-1 = left, 0 = middle, 1 = right)
          treeDirection = arrangement!='horizontal'? (arrangement=='left'?1:-1):
                          (index==parent.children.length-1&&treeDirection>=0?1:index==0&&treeDirection<0?-1:0);
          
          // store the required interlink x axis change required out of node by looking at underhanging children and the default deepArrangement setting
          child.data.side = child.data.isTopOrBottom!=null?0: treeDirection == 0? (arrangement=='left'?1:-1) * (ownDepth[(arrangement=='left'?2:1)] + this.config.nodeWidth/1.5):
                            treeDirection * (ownDepth[(treeDirection<0?1:2)] + this.config.nodeWidth/1.5);

          // loop children and add all sub children
          this.arrangeChildren(child,arrangeDeep,treeDirection,arrangement=="horizontal");
        }else {
          if(arrangement.match(/left|right/)){
            child.data.side = (arrangement=='left'?-1:1) * this.config.nodeWidth/1.5;
          }
          else child.data.isTopOrBottom = -1; // if its the last node on the branch set it to bottom (top == -1)
        }
        if(parent.children.length==1) child.data.isOnlyChild = true;
        
        
       
        // if a child limit is set and the limit is reached but more childs follow set class to 
        if(this.config.stackChildLimit && index == this.config.stackChildLimit - 1){
          child.class = "stack";
          break;
        }else index++;
      }
      // center the new branch horizontally
      if(xOffsetChange>0&&arrangement=='horizontal'&&parent.children.length>1) for (let child of parent.children){
        this.changeSpacing(child,xOffsetChange/2)
        }
      }
    },
    changeSpacing(parent,offset){
      parent.x -= offset;
      if(parent.children) for(let child of parent.children){
        if(child.children) this.changeSpacing(child,offset);
        else child.x -= offset;
      }
    },
    // limit the amount of children before tree building to make everything as narrow as wanted
    limitChildCount(parent){
      if(parent.children){
        let hardLimit = 4;
        let softLimit = 3;
        if(parent.children.length>softLimit){
          parent.__children = [...parent.children];
          parent.children = parent.children.slice(0,parent.children.length>hardLimit?2:3);
        }
        for(let child of parent.children){
          if (child.children) this.limitChildCount(child);
        }
      }
      return parent;
    },
    // checks if there are no nodes on the same Y level (left or right) in between, filters the ones that are, sorts them on Y-axis and gives back the needed Y
    checkNodesOnSameYInBetween(source,target){
      let nodesArray = [source,target]
      let nodesXsortedAsc = nodesArray.sort((a,b)=>a.x==b.x?0:a.x<b.x?-1:1);
      let nodesYsortedDesc = nodesArray.sort((a,b)=>a.y==b.y?0:a.y>b.y?-1:1);
      let lowerNotes = this.nodeDataList.filter(node=>
        node.x >= nodesXsortedAsc[0].x && node.x <= nodesXsortedAsc[1].x // check that the node lies in between
        && node.y > nodesYsortedDesc[0].y // and is lower than the lowest node
      )
      return lowerNotes&&lowerNotes.length>0?lowerNotes.find(node=>node.data.id == target.data.id)!=null?this.linkDataList.filter(link=>link.source.data.id == source.data.id || link.target.data.id == source.data.id).sort((a,b)=>a.y==b.y?0:a.y<b.y?-1:1)[0].y - this.config.nodeHeight:lowerNotes.sort((a,b)=>a.y==b.y?0:a.y>b.y?-1:1)[0].y + this.config.nodeHeight:0;
    },
    generateLinkPath(d) {
      const self = this
      if (this.linkStyle === LinkStyle.CURVE) {
        const linkPath = this.isVertical()
          ? d3.linkVertical()
          : d3.linkHorizontal()
        linkPath
          .x(function (d) {
            return d.x
          })
          .y(function (d) {
            return d.y
          })
          .source(function (d) {
            const sourcePoint = {
              x: d.source.x,
              y: d.source.y
            }
            return self.direction === self.DIRECTION.VERTICAL
              ? sourcePoint
              : rotatePoint(sourcePoint)
          })
          .target(function (d) {
            const targetPoint = {
              x: d.target.x,
              y: d.target.y
            }
            return self.direction === self.DIRECTION.VERTICAL
              ? targetPoint
              : rotatePoint(targetPoint)
          })
        return linkPath(d)
      }
      if (this.linkStyle === LinkStyle.STRAIGHT) {
        // the link path is: sourcePoint -> secondPoint -> thirdPoint -> fourthPOint -> targetPOint
        const linkPath = d3.path()
        let sourcePoint = { x: d.source.x+(d.class?10:0), y: d.source.y+(d.class?5:0) } // skew the interlinks a bit to avoid overlapping with normal links
        let targetPoint = { x: d.target.x+(d.class?10:0), y: d.target.y+(d.class?5:0) }
        if (!this.isVertical()) {
          sourcePoint = rotatePoint(sourcePoint)
          targetPoint = rotatePoint(targetPoint)
        }

        // calc the x and y changes 
        const xOffset = targetPoint.x - sourcePoint.x
        const yOffset = targetPoint.y - sourcePoint.y
        let requiredYValue = 0;
        if(d.class && d.startY == 0){
          requiredYValue = this.checkNodesOnSameYInBetween(d.source,d.target);
        }
        // second point takes the link out of the node into the required direction (with side over the childs, or top down by small margin)
        // if target is on child, then look at deepArrange and go other side 
        const secondPoint = d.class?{ x: sourcePoint.x + ((d.source.data.isOnlyChild&&xOffset<0&& d.startX<0||xOffset>0&&d.startX>0?-1:1)*(d.startX!=0?d.startX+(requiredYValue>0&&d.targetX>0?-100:1):0)) , y: sourcePoint.y +(d.startY!=0?d.startY:0) }:
          d.type&&d.type.match(/left|right/)
          ? { x: sourcePoint.x, y: sourcePoint.y + yOffset / 1 }
          : { x: sourcePoint.x, y: sourcePoint.y + yOffset / 2 }
        const fifthPoint = d.class? //!this.isVertical()
          { x: targetPoint.x +((d.target.data.isOnlyChild&&xOffset<0&& d.targetX<0||xOffset>0&&d.targetX>0?-1:1)*(d.targetX!=0?d.targetX:0)) , y: targetPoint.y +(d.targetY!=0?d.targetY:0) }:null;

        const fourthPoint = d.class? //!this.isVertical()
          { x: fifthPoint.x , y:  (d.targetX!=0?requiredYValue>0?requiredYValue:fifthPoint.y:fifthPoint.y) }:null; 

        const thirdPoint = d.class?{ x: (d.startX!=null?secondPoint.x:fourthPoint.x), y: fourthPoint.y }:
          d.type&&d.type.match(/left|right/)?//!this.isVertical()
           { x: targetPoint.x, y: sourcePoint.y + yOffset / 1.6 }
          : { x: targetPoint.x, y: sourcePoint.y + yOffset / 2 }//{ x: sourcePoint.x + xOffset / 2, y: targetPoint.y }
        /* const fourthPoint = //!this.isVertical()
           { x: sourcePoint.x+(d.class?this.config.treeOffset/1.6:0), y: sourcePoint.y + yOffset / 1.6 }
         *//* const sourcePath = this.storedPoints.find(obj=>obj.second.x == sourcePoint.x && obj.third.x == secondPoint.x && obj.third.y == sourcePoint.y && obj.second.y == secondPoint.y);
        const overlapPath = this.storedPoints.find(obj=>obj.second.x == secondPoint.x && obj.third.x == thirdPoint.x && obj.third.y <= secondPoint.y && obj.second.y >= thirdPoint.y|| obj.second.y == secondPoint.y && obj.third.y == thirdPoint.y && obj.third.x <= secondPoint.x && obj.second.x >= thirdPoint.x);
        const insidePath = this.storedPoints.find(obj=>obj.second.x == secondPoint.x && obj.third.x == thirdPoint.x && obj.third.y >= secondPoint.y && obj.second.y <= thirdPoint.y|| obj.second.y == secondPoint.y && obj.third.y == thirdPoint.y && obj.third.x >= secondPoint.x && obj.second.x <= thirdPoint.x);
        if(sourcePath==null){
          this.storedPoints.push({second:sourcePoint,third:secondPoint}); 
        }else sourcePoint = {x:sourcePath.second.x == secondPoint.x && sourcePath.third.x == thirdPoint.x?secondPoint.x:sourcePath.third.x,
            y:sourcePath.second.y == secondPoint.y && sourcePath.third.y == thirdPoint.y?secondPoint.y:sourcePath.third.y};
        if(insidePath==null||overlapPath!=null){
          this.storedPoints.push({second:secondPoint,third:thirdPoint}); 
          if(overlapPath!=null) sourcePoint = {x:overlapPath.second.x == secondPoint.x && overlapPath.third.x == thirdPoint.x?secondPoint.x:overlapPath.third.x,
            y:overlapPath.second.y == secondPoint.y && overlapPath.third.y == thirdPoint.y?secondPoint.y:overlapPath.third.y};
         
          
          
        } else */  
        linkPath.moveTo(sourcePoint.x, sourcePoint.y)
          linkPath.lineTo(secondPoint.x, secondPoint.y);
          linkPath.moveTo(secondPoint.x, secondPoint.y)
        //console.log(this.storedPoints,overlapPath)
        if(d.class||!d.type||d.type=='horizontal'){
          linkPath.lineTo(thirdPoint.x, thirdPoint.y);
          linkPath.moveTo(thirdPoint.x, thirdPoint.y)
        }
        if(d.class){
          linkPath.lineTo(fourthPoint.x, fourthPoint.y)
          linkPath.moveTo(fourthPoint.x, fourthPoint.y)
          linkPath.lineTo(fifthPoint.x, fifthPoint.y)
          linkPath.moveTo(fifthPoint.x, fifthPoint.y)
        }
        linkPath.lineTo(targetPoint.x, targetPoint.y);
        return d.source.y==0?null:linkPath.toString()
      }
    },
    // if an interlink is present on inside and not bottom, and the beelow space on its side is big it needs to have fake siblings to fill the space
    // these have no link, are .hidden
    addfakeSiblings(child,xAxisSpaceNeeded){
      // if parent is solo, go up one more and add to generationSibling

      // make fake sibling(s) if no more solo parent

      // add as many as needed to fill xAxis space before or after the current child
    },
    findIntersects(){

    },
    //
    findNextEndpoint(){
      
    },
    // 
    checkCrossingInterlinks(){
      // i
        // if an interlink 
    },
    // 使用扇形数据开始绘图
    draw(init=false) {
      
     // d3 rendering of the tree
       if(this.dataset==null) return null;
       this.dataset.children.forEach(ch=>ch = this.limitChildCount(ch));
        const [nodeDataList] = this.buildTree(this.dataset);
        this.linkDataList = [];//linkDataList
        this.nodeDataList = nodeDataList
        this.storedPoints = [];
      
    // extra spacing and calculating bounding box of each tree
    if(this.nodeDataList[0].children){
      /*
      let temp = Object.values(this.nodeDataList).find(el=>el.data.id==71)
      if(temp) temp.data.arrangement='below';
      */
    
      let lastWidth = 0;
      let regionWidth = 0;
      let regionHeight = 0;
      let  regionTop = 0;
      let regionLeft = 0;
      let nextRegion=false;
      let extraXoffset=0;

      let bbRegionaal = [];
      for(let tree of this.nodeDataList[0].children.filter(n=>n.data.domain==='regionaal')){
        bbRegionaal = this.getBoundingBox(tree,bbRegionaal);
      }  
      let bbLandelijk = [];
      for(let tree of this.nodeDataList[0].children.filter(n=>n.data.domain==='landelijk')){
        bbLandelijk = this.getBoundingBox(tree,bbLandelijk);
      }  
      
      this.nodeDataList[0].children.forEach((child,i)=>{

        // apply the new arrangements to each child
        this.arrangeChildren(child);
        
        // add space around collapsed top nodes, avoinds collapsed nodes clinging together

        //if(regionWidth==0&&i>0&&(child.children==null||child.children.length==1)) child.x -= this.nodeDataList[0].children[i-1].data.regionWidth/1.25;

        // add bounding box limits of each tree to its top node
        let bb = this.getBoundingBox(child);  

        // using bb results fill the top nodes parameters (width height etc)
        child.data.width = (bb[1]-bb[0])<this.config.nodeWidth?this.config.nodeWidth:(bb[1]-bb[0]);
        child.data.left = bb[0]//(child.x||child.data.x||0);
        child.data.top = -100//bb[2]-;
        child.data.height = 250+(bb[3]-bb[2]>0?bb[3]-bb[2]:this.config.nodeHeight*3);
        if(this.maxHeight<child.data.height) this.maxHeight = child.data.height;
        if(this.leftSide>child.data.left) this.leftSide = child.data.left;
        if(this.rightSide<bb[1]) this.rightSide = bb[1];
        
        // reduces the distance between trees to a mininum
        if(lastWidth>0 &&  (nextRegion  ||/* |(child.data.left - this.nodeDataList[0].children[i-1].data.left -lastWidth)<200 || i==1 && child.children!=null &&child.children[0].children!=null || */ this.nodeDataList[0].children[i-1].data.regionLast)){
        if(this.nodeDataList[0].children[i-1].data.regionLast){// && 200<(child.data.left - this.nodeDataList[0].children[i-1].data.left - this.nodeDataList[0].children[i-1].data.regionWidth)<100){
          extraXoffset = -300+this.getBoundingBox(child)[0]- this.getBoundingBox(this.nodeDataList[0].children[i-1])[1];
          this.changeSpacing(child ,extraXoffset);
        //else if(child.data.left - this.nodeDataList[0].children[i-1].data.left - lastWidth <200)  this.changeSpacing(child ,(child.data.left - this.nodeDataList[0].children[i-1].data.left -lastWidth)-250);
        }else if(nextRegion) this.changeSpacing(child ,extraXoffset);
        //else this.changeSpacing(child ,700)-(this.nodeDataList[0].children[i-2].data.regionLast?300:0)
        // bb = this.getBoundingBox(child);
        //child.data.left = bb[0]==Infinity?this.config.nodeWidth*2.5:bb[0]
        
        bb = this.getBoundingBox(child);
        child.data.left = bb[0]
        //(child.x||child.data.x||0);
        }

        // add to regions size variables
        if(regionWidth==0){
          regionTop = child.data.top;
          regionLeft = child.data.left;
        }
        regionWidth += child.data.width + (i!=0||i>0&&!this.nodeDataList[0].children[i-1].data.regionLast?child.data.left - this.nodeDataList[0].children[i-1].data.left - child.data.width + 70:this.nodeDataList[0].children[i==0?0:i-1].children==null?0:200)// - (i<3&&child.children==null?child.data.width:0);
        regionHeight = Math.max(regionHeight,child.data.height);

        // store the region size data to its last child
        if(child.data.regionLast){
          nextRegion = true;
          child.data.regionWidth = (child.data.domain=='regionaal'?bbRegionaal[1]-bbRegionaal[0]:bbLandelijk[1]-bbLandelijk[0]) - (regionLeft+(child.data.domain=='regionaal'?bbRegionaal[1]-bbRegionaal[0]:bbLandelijk[1]-bbLandelijk[0])-this.config.nodeWidth/2 - bb[1] - this.config.nodeWidth/2)// + 350//regionWidth*0.75 + (child.data.domain=='landelijk'?150:0);
          child.data.regionHeight = regionHeight+this.config.nodeHeight*3;
          child.data.regionTop = regionTop;
          child.data.regionLeft = regionLeft-this.config.nodeWidth/2;
          // reset the counters
          regionWidth = 0;
          regionHeight = 0;
          regionTop = 0;
          regionLeft = 0;
        }

        
        // add more space in between different trees
        lastWidth = child.data.width;
      });

     }
     //this.initTransform();
     if(init) globals.nodeList = this.nodeDataList;

      // add interlinks
      const identifier = this.dataset['identifier']
      const specialLinks = this.dataset['links']
      if (specialLinks && identifier) {
        for (const link of specialLinks) {
          let parent,
            children = undefined

            parent = this.nodeDataList.find((d) => {
              return d['data'][identifier] == link.parent
            })
            children = this.nodeDataList.filter((d) => {
              return d['data'][identifier] == link.child
            })
            //console.log(this.nodeDataList,children,parent)
          //}
          if (parent && children) {
            for (const child of children) {
              const new_link = {
                source: parent,
                target: child,
                class:'interlink ',
                startX: parent.data.side||0,
                startY: parent.data.isTopOrBottom!=null?-1*parent.data.isTopOrBottom*this.config.nodeHeight:0,
                targetX: child.data.side||0,
                targetY: child.data.isTopOrBottom!=null?-1*child.data.isTopOrBottom*this.config.nodeHeight:0,
              }
              this.linkDataList.push(new_link);
              
            }
          }
        }
        
      }

      // check the onterlinks on that that all children have one parent, so this child is also a 
       /* this.linkDataList.forEach(link=>{
         if(link.class && this.linkDataList.filter(lnk=>lnk.source.id == link.target.id || lnk.target.id == link.target.id).length>1){
           let parent = link.source;
           link.source = link.target;
           link.target = parent;
         }
       }) */
      
      this.svg = this.d3.select(this.$refs.svg)
      
      const self = this
      const links = this.svg.selectAll('.link').data(this.linkDataList, (d) => {
        return `${d.source.data.id}-${d.target.data.id}`
      })

      links
        .enter()
        .append('path')
        /* .style('opacity')
        .transition()
        .duration(ANIMATION_DURATION)
        .ease(d3.easeCubicInOut)
        .style('opacity', 1)*/
        .attr('class', (d)=>(d.class?d.class+' region-'+d.source.data.treeId+' region-'+d.target.data.treeId+' ':'')+'link') 
        .attr('d', function (d) {
          return self.generateLinkPath(d)
        })
      links
        .transition()
        .duration(ANIMATION_DURATION)
        .ease(d3.easeCubicInOut)
        .attr('d', function (d) {
          return self.generateLinkPath(d)
        })
      links
        .exit()
        .transition()
        .duration(ANIMATION_DURATION / 2)
        //.ease(d3.easeCubicInOut)
        .style('opacity')
        .remove()
        
        // when in development mode re scale to fit, when this file has rendered
        //if(window.webpackHotUpdate) setTimeout(()=>document.getElementById('scaletofit').click(),400);
    },
    buildTree(rootNode) {
      const treeBuilder = this.d3
        .tree()
      .separation(function separation(a, b) {
        return a.parent == b.parent ? 3.5 : 0.25;
    })
        .nodeSize([this.config.nodeWidth, this.config.levelHeight])
      const tree = treeBuilder(this.d3.hierarchy(rootNode))
      return [tree.descendants(), tree.links()]
    },
    getDistance(x1, y1, x2, y2){
      const sqrDistance = (x1 - x2) ** 2 + (y1 - y2) ** 2;
      const distance = Math.sqrt(sqrDistance);
      return distance;
    },
    enableDrag() {
      // block browser scaling
      document.addEventListener('touchmove', function (event) {
        if (event.scale !== 1) { event.preventDefault(); }
      }, { passive: false });

      const svgElement = this.$refs.view
      const container = this.$refs.view
      let startX = 0
      let startY = 0
      let isDrag = false
      let isDblClicked = false
      // 保存鼠标点下时的位移
      let mouseDownTransform = '';
      let dist0 = undefined;
      /*container.ontouchstart =  (event) =>{
        //if(!event.target.classList.contains('node')) globals.dontOpenModal = true;
        if (event.targetTouches.length == 2) {//check if two fingers touched screen
          dist0 = Math.hypot( //get rough estimate of distance between two fingers
          event.touches[0].pageX - event.touches[1].pageX,
          event.touches[0].pageY - event.touches[1].pageY);                  
        }else if(event.touches.length==1){
          startX = event.touches[0].clientX
          startY = event.touches[0].clientY
          isDrag = true
          mouseDownTransform = svgElement.style.transform
          isDblClicked = false
        }
      }
      container.addEventListener('touchmove', (event) =>{
          if (event.scale !== 1) { event.preventDefault(); }
          if (event.touches.length === 2) {
              let hypo1 = Math.hypot((event.touches[0].pageX - event.touches[1].pageX),
                  (event.touches[0].pageY - event.touches[1].pageY));
              if (dist0 === undefined) {
                  dist0 = hypo1;
              }
              if(dist0){
                this.zoom(hypo1/dist0>1,{clientX:event.touches[0].clientX,clientY:event.touches[0].clientY,deltaY:(1-hypo1/dist0)/0.005},false); //1 - event.deltaY * 0.0005
                isDrag = false
              }
          }else if(event.touches.length==1){
            this.doDrag(event.touches[0].clientX,event.touches[0].clientY,startX,startY,mouseDownTransform);
           }
      }, { passive: false });


      container.ontouchend = (event) =>{
        globals.dontOpenModal = false;
        if(isDrag&&document.querySelector('.tree-view').style.transform.match(/scale\(([\d.]+)\)/)[1]<globals.minScale*2&&!this.elementIsInViewport(this.$refs.view))  EventBus.$emit("ui_click", 'scale_to_fit_noZoom')
        startX = 0
        startY = 0
        isDrag = false
        isDblClicked = false
        dist0 = undefined;
      }
      
      container.onmousedown = (event) => {
        //if(event.target.classList.contains('node')){
          globals.dontOpenModal = false;
        //}else globals.dontOpenModal = true;
        event.preventDefault();
        isDblClicked = false
        if(document.querySelector('.tree-view').style.transform.match(/scale\(([\d.]+)\)/)[1]<globals.minScale*2&&(event.clientX<10||event.clientX>this.$refs.container.clientWidth*1.5-10||event.clientY<10||event.clientY>this.$refs.container.clientHeight*1.5-10||!this.elementIsInViewport(this.$refs.view))){
            EventBus.$emit("ui_click", 'scale_to_fit_noZoom');
            //isDrag = false;
          //return null
        }else{
          //this.int = setTimeout(()=>EventBus.$emit("ui_click", 'scale_to_fit'),500)
          mouseDownTransform = svgElement.style.transform
          startX = event.clientX
          startY = event.clientY
          isDrag = true;
        }
      }*/
      container.ondblclick = (event) => {
        isDblClicked = !isDblClicked;
        //if(isDblClicked){ 
          globals.dontOpenModal = true;
          if(event.target.classList.contains('background-tree')){
           // globals.selectedId = event.target.getAttribute('data-treeid');
           this.scaleToFit(event.target.getAttribute('data-treeid'))
          }/*else{
            mouseDownTransform = svgElement.style.transform
          startX = event.clientX
          startY = event.clientY
          isDrag = true;
          }*/
        //}
      }
      
      /*container.onmousemove = (event) => {
        event.preventDefault();
        if(isDrag) globals.dontOpenModal = true;
        if (!isDrag || event.clientX <=0 || event.clientY <=0) return
        this.doDrag(event.clientX,event.clientY,startX,startY,mouseDownTransform);
        //EventBus.$emit("ui_click", 'scale_to_fit_noZoom');
      }
      container.onmouseleave = (event)=>{
        if(isDrag){
          let offset = 100;
          if(document.querySelector('.tree-view').style.transform.match(/scale\(([\d.]+)\)/)[1]<globals.minScale*2) EventBus.$emit("ui_click", 'scale_to_fit_noZoom');
          else this.doDrag(event.clientX+(event.clientX<=0?offset:event.clientX>=window.innerWidth?-offset:0),event.clientY+(event.clientY<=0?offset:event.clientY>=window.innerHeight?-offset:0),event.clientX,event.clientY,svgElement.style.transform)
        }
        isDrag = false;
      }
      container.onmouseup = (event) => {
        clearTimeout(this.int);
        if(!isDrag) globals.dontOpenModal = false;
        let minBorderOffset = 100;
        if(document.querySelector('.tree-view').style.transform.match(/scale\(([\d.]+)\)/)[1]<globals.minScale*2&&(event.clientX<minBorderOffset||event.clientX>window.innerWidth-minBorderOffset||event.clientY<minBorderOffset||event.clientY>window.innerHeight-minBorderOffset||!this.elementIsInViewport(this.$refs.view,true))){
            EventBus.$emit("ui_click", 'scale_to_fit_noZoom');
            isDrag = false;
          //return null
        }
        //if(! && event.target.classList.contains('node')) event.target.click();
        if(!isDblClicked){
          startX = 0
          startY = 0
          isDrag = false
        }
      }*/
    },
    doDrag(x,y,startX,startY,originTransform){
        let originOffsetX = 0
        let originOffsetY = 0
        if (originTransform) {
          const result = originTransform.match(MATCH_TRANSLATE_REGEX)
          if (result !== null && result.length !== 0) {
            const [offsetX, offsetY] = result.slice(1)
            originOffsetX = parseInt(offsetX)
            originOffsetY = parseInt(offsetY)
          }
        }
        let newX =
          Math.floor((x - startX) / document.querySelector('.tree-view').style.transform.match(/scale\(([\d.]+)\)/)[1]) +
          originOffsetX
        let newY =
          Math.floor((y - startY) / document.querySelector('.tree-view').style.transform.match(/scale\(([\d.]+)\)/)[1]) +
          originOffsetY
        let transformStr = `translate(${newX}px, ${newY}px) translateZ(0)`
        if (originTransform) {
          transformStr = originTransform.replace(
            MATCH_TRANSLATE_REGEX,
            transformStr
          )
        }
        /* svgElement.style.transform = transformStr
        this.$refs.domContainer.style.transform = transformStr */
        this.$refs.view.style.transform = transformStr
         // check if the container gets out of bounds
    },
    onClickNode(index,treeId=null) {
      if (this.collapseEnabled) {
        const curNode = treeId!=null?this.nodeDataList.find(el=>el.data.treeId == treeId && el.data.isTopOrBottom!=null):this.nodeDataList[index];
        if (curNode.data.children||curNode.children) {
          curNode.data._children = curNode.data.children||curNode.children
          curNode.data.children = null
          curNode.children = null
          curNode.data._collapsed = true
        } else {
          curNode.data.children = curNode.data._children
          curNode.children = curNode.data._children
          curNode.data._children = null
          curNode.data._collapsed = false
        }
        this.draw()
      }
    },
    setArrangement(index,arrangement){
      const curNode = this.nodeDataList[index];
      curNode.data.arrangement = arrangement;
      this.draw(true);
    },
    formatDimension(dimension) {
      if (typeof dimension === 'number') return `${dimension}px`
      if (dimension.indexOf('px') !== -1) {
        return dimension
      } else {
        return `${dimension}px`
      }
    },
    parseDimensionNumber(dimension) {
      if (typeof dimension === 'number') {
        return dimension
      }
      return parseInt(dimension.replace('px', ''))
    }
  },
  watch: {
    dataset() {
      this.draw(true)
    }
  }
}
</script>

<style>
.tree-container .node {
    fill: grey !important;
  }

 .tree-container .link {
    stroke-width: 4px !important;
    fill: transparent !important;
    stroke: #f00 !important;
  }

 .tree-container .interlink{
    stroke-width: 6px !important;
    stroke: #7b7b7b !important;
  }
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
 
@keyframes dashd {
  to {
    stroke-dasharray: 5px;
  }
}
.tree-container .link,  svg.showinterlinks .interlink{
  stroke-dasharray: 5000;
  stroke-dashoffset: 5000;
  animation: dash 5s ease-out forwards;
  animation-delay: 0.45s;
  transform: translateZ(0);
}
svg .interlink{
  display:none !important;
}
.tree-container svg.showinterlinks .interlink{
  animation: dashd 0.5s ease-out forwards;
  animation-delay: 0s;
  transform: translateZ(0);
}

.tree-node-item-enter,
.tree-node-item-leave-to {
  transition-timing-function: ease-in-out;
  transition: transform 0.8s;
  opacity: 0;
}

.tree-node-item-enter-active,
.tree-node-item-leave-active {
  transition-timing-function: ease-in-out;
  transition: all 0.8s;
}

.tree-container, .tree-view {
    width: 100%;
    height: 100vh;
    /*
    left: 0;
      top: 0;
    position: absolute;
    */
  
}
  .tree-container{
    position: relative;
    backface-visibility: hidden;
  }

  .tree-container  svg,
  .dom-container, .background-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: visible;
    transform-origin: 0 50%;
  }

.node-slot {
  position: absolute;
  background-color: transparent;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  transition: all 0.5s;
  z-index: 3;
  font-size: 18px;
  transition-timing-function: ease-in-out;
}
.highlightmode .node-slot:not(.highlighted),.highlightinterlinks .link, .highlightlinks .interlink{
  filter: contrast(0.35) brightness(1.45);
}
.highlightinterlinks .interlink, .highlightlinks .link{
  stroke-width: 10px !important;
}
.highlightinterlinks .interlink{
  stroke-width: 18px !important;
}
/*
.highlighted{
  border: 7px dashed #1a202c63;
}
*/
.rich-media-node{
  transition: all 0.5s;
}
.highlighted .rich-media-node{
  transform: scale(1.2);
  box-shadow: 0 0 0 10px lightgrey;
}

.node-slot:first-child{
  display:none;
}
.background-tree{
  opacity:0;
}
.background, .background-tree{
  text-align: center;
  z-index: 3;
  border-radius: 150px;
  position: absolute;
  transition: all 0.8s;
}
.background{
  background: #fff; 
  opacity: .7;
  z-index: -1;
}
.background-tree button{
  display:none;
  cursor: pointer;
  position: relative;
  float: right;
  height: 50px;
  width: 50px;
  opacity: 0.3;
  top: 55px;
  right: 55px;
}
.background-tree:hover button{
  display:block;
}
.background-tree:hover{
  border:solid 3px lightgrey
}

</style>
