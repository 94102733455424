<template>
  <div class="tree_main fixed w-full h-full top-0 left-0 overflow-hidden">
    <div id="modal-backdrop" v-if="showModal" @click="toggleModal(node)"></div>
    <div id="modal" v-if="showModal" v-html="modalData"></div>
    <panZoom :options="{minZoom: 0.1, maxZoom: 1.5,transformOrigin: null,bounds: false,onTouch: function(e) {return false;},beforeWheel:(typeof window.orientation !== 'undefined'),zoomDoubleClickSpeed: 1}" selector="#tree" @init="onInit" style="width:100vw;height:100vh;">
    <tree
      id="tree"
      style=""
      :dataset="treeData"
      :config="treeConfig"
      linkStyle="straight"
    > <!-- ,setArrangement -->
      <template v-slot:node="{ node, collapsable, collapsed, index, collapse }" >
        <div
          class="rich-media-node node rounded subpixel-antialiased pointer-events:all" :class="{'border-2 border-galaxy text-galaxy':node.blocktype === '5','text-white': node.blocktype === '2' || node.blocktype === '1'}" :style="{backgroundColor:types&&Object.values(types).find(el=>el.id==node.blocktype)&&Object.values(types).find(el=>el.id==node.blocktype).color||'grey'}"
        >
        <div v-if="node.top" class="hidden" :style="{pointerEvents:'all',right: -node.regionWidth/2.3+(node.treeId>0?150:0)+'px',top: node.regionTop-65+'px',color:'black',position:'absolute'}">
        <button @click="scaleToFit(node)" class="h-20 w-20 material-icons">open_in_full</button>
         <button @click="collapse(null,node.treeId)" class="h-20 w-20 material-icons">account_tree</button>
        <button @click="toggleInterlinks()" class="h-20 w-20 material-icons">link</button>
        </div>
          <table class="w-full h-full node-content node" @mousedown="panStart" @mouseup="panEnd"
            ><!-- @click.prevent="toggleModal(node)"input v-model.lazy="node.name" v-debounce="1200" placeholder="add title" style="" :class="'!w-40 text-s bg-transparent text-black text-center'"/-->
          <td style="vertical-align: middle;">{{node.title}}<br/>
          <!--  
          <p v-html="node.text"></p>
          -->
          <!--span class="material-icons" style="display: inline-block" @click="node.name = node._name">settings_backup_restore</span--></td></table
          >

          <div id="expand" style="pointer-events:all" v-if="collapsable" @click="collapse(index)" :class="{'expanded':!collapsed}" :style="{display:collapsable?'inline-block':'none'}">
            <span id="expandbutton"  class="material-icons h-15 w-15 text-blue-500 button" >{{collapsable&&!collapsed ?'remove_circle_outline':'add_circle_outline'}}</span>
            <!--span class="material-icons h-5 w-5 text-blue-500 button"  @click="setArrangement(index,'left')">align_horizontal_left</span>
            <span class="material-icons h-5 w-5 text-blue-500 button"  @click="setArrangement(index,'horizontal')">horizontal_rule</span>
            <span class="material-icons h-5 w-5 text-blue-500 button" @click="setArrangement(index,'right')">align_horizontal_right</span-->
          </div>

        </div>
        <!--div class="overlay pointer-events-none" v-if="node.regionLast" :style="{left:regionLeft+'px',top:regionTop+'px',width:regionWidth+'px',height:regionHeight+'px',position:'absolute','textAlign':'center'}">
          <span class="h-80 w-full p-40" style="display:block;background:rgba(255,255,255,0.8);border-radius:250px;padding-top:20px;color:#85b4ae;font-weight:bold;font-size:40px;pointer-events:all">
            <button @click="collapseExpandAll(false,treeId)" style="cursor:pointer;font-size: 68px;top: 18px;position: relative;padding-right: 20px;" class="material-icons-outlined text-galaxy hover:text-poppy transition duration-300 ease-in-out">{{regionIsHidden(treeId)?'visibility_off':'visibility'}}</button>{{regionNames[node.region]}}</span>
        <button @click="collapse(index)" style="pointer-events:all;right: 60px;top: 30px;" class="absolute h-20 w-20"><span class="material-icons">account_tree</span></button>
        <button @click="toggleInterlinks()" style="pointer-events:all;right: 120px;top: 30px;" class="absolute h-20 w-20"><span class="material-icons">link</span></button>
          
        </div-->
      </template>
    </tree>
    </panZoom>
  </div>
</template>

<script>
import { globals } from "@/modules/globals";
import { EventBus } from '@/modules/event_bus'
import Tree from './Tree.vue'
import gsap from 'gsap'
export default {
  name: "TreeView",
  props: [],
   components:{Tree},
  data() {
    return {
      window: window,
     regionNames:["Regionaal","Landelijk"],
      tree_data: globals.api_data.fetch_tree.tree_data,
      contacts: globals.api_data.fetch_tree.contacts_data,
      types: globals.api_data.fetch_tree.types_data,//{"bestuursniveau": "black", "directeurenniveau":"#889483", "managementniveau":"#85b4ae", "ambtelijkniveau":"#c29624", "projectwerklijn":"white"},
      relations: null,
      treeConfig: { nodeWidth: 260, nodeHeight: 90, levelHeight: 145, 
      treeOffset:190,paddingAbove: 120,stackChildLimit: null, paddingRight: 80,
        innerChildLimit: 2, startArrangement: 'horizontal', deeperArrangement: 'left' },
      treeData: null,
      scale:0.2,
      minScale:0.1,
      w: window.outerWidth,
      h: window.outerHeight,
      clickedNodeId:null,
      dragX:0,
      dragY:0,
      showModal: false,
      treeDataRaw: null,
      panzoomInstance:null,
      int:null,
      zoomInt:null
    }
  },
  async mounted() {
    await this.createHierarchicalTree(this.tree_data);
    EventBus.$on('ui_click', (e) => {
      this.ui_event(e)
    })
    EventBus.$on('search', (e) => {
      this.search(e)
    })
    this.int = setInterval(()=>{
        if(this.treeDataRaw!=null){
          clearInterval(this.int);
      this.treeData = Object.assign(this.treeDataRaw,
      {
        links : this.tree_data.filter(el=>el.related!=null).map(src=>{ return [src.related].map(tgt=>{return {parent:src.id,child: tgt }})}).length>0?
         this.tree_data.filter(el=>el.related!=null).map(src=>{ return [src.related].map(tgt=>{return {parent:src.id,child: tgt }})}).reduce((a,b)=>a.concat(b)):[]
      ,
        identifier:'id'});

      // @Roberto ik zet de interlinks hier even bij het beginuit
      gsap.delayedCall(0.5, this.toggleInterlinks)
        
      setTimeout(this.scaleToFit,500);
        }},500);
  },
  
    computed: {
         
    },
    created() {
      // when in development mode re scale to fit, when this file has rendered 
       setTimeout(()=>{ if(window.webpackHotUpdate&&document.querySelector('.tree-view').clientWidth<350) location.reload()},1000);
       // window resize means rescale to fit
       /*
      window.onresize = (evt) =>{
        this.w = window.outerWidth;
        this.h = window.outerHeight;
        this.scaleToFit();
      }
      */
    },
    methods: {
    search(query){
      if(query==null||query=='') return globals.search_results = null;
      let departments = this.tree_data.filter(afd=>afd.title.match(new RegExp(query,'i')));
      let persons_raw =  Object.values(this.contacts).filter(pers=>pers.name.match(new RegExp(query,'i')));
      let persons = [];
      persons_raw.forEach(pers=>this.tree_data.filter(afd=>afd.people.deelnemers.indexOf(pers.id)>-1||afd.people.secretarissen.indexOf(pers.id)>-1||afd.people.voorzitters.indexOf(pers.id)>-1).forEach(node=>persons.push(Object.assign({department:node},pers))));
      globals.search_results = [persons,departments];//document.getElementById('search_results').innerHTML = (persons.length>0?'</div></div><div class="persons"><div class="row">'+persons.map(pers=>'<span class="w-1/2 inline-block">'+pers.name+'</span><span class="w-1/2 inline-block">- &nbsp;&nbsp;'+pers.department.title+'</span>').join('</div><div class="row">')+'</div></div>':'')+(departments.length>0?'<div class="departments"><div class="row">'+departments.map(dep=>dep.title).join('</div><div class="row">'):'');
      if(globals.search_results[0].length>0||globals.search_results[1].length>0){
        setTimeout(()=>{
          document.getElementById('search_results').classList.remove('show');
        document.getElementById('search_results').classList.add('show');
        },300);
        setTimeout(()=>{
          if(document.getElementById('search_results').clientHeight==0) document.getElementById('search_results').classList.remove('show')
          else document.getElementById('search_results').classList.add('show')
      },3000);
      }
    },
    logout(){
      globals.loggedIn = false
      EventBus.$emit('logout_success')
    },
    toggleInterlinks(){
      document.getElementsByTagName('svg')[0].classList.toggle('showinterlinks');
    },
     createHierarchicalTree: function(data){
      // create a hierarchical tree from flat array
      let treeData = {name:'top_parent',id:999999,children:[]};
       data.forEach((node)=> {

        if(node.distribution == '1'){
          node.arrangement = "below"
        }

        let parent = data.find(el=>el.id == node.parent);
        if (parent) {
          // add a child under an existing tree
          (parent.children || (parent.children = [])).push(Object.assign(node,{treeId:parent.treeId}));
        } else {
          // add a new tree
          treeData.children.push(Object.assign(node,{isTopOrBottom:1,treeId:treeData.children.length,regionLast:false, hidden:false}));
        }
      });
      // sort the nodes by region
      treeData.children = treeData.children.sort((a,b)=>a.domain.charCodeAt()==b.domain.charCodeAt()?0:a.domain.charCodeAt()>b.domain.charCodeAt()?-1:1)//.filter((nd,i)=>i>-1&&i<4);
      // add regionLast flag to last top node of each region
      treeData.children.forEach((node,i)=>{
        if(i==treeData.children.length-1||node.domain!==treeData.children[i+1].domain) node.regionLast = true;
        if(i==treeData.children.length-1){
          this.treeDataRaw = treeData;
        }
      })
    },
    blockWheelOnMobile(e){
      return false;
    },
    onInit(instance, id){
      instance.on('pan', (e)=> {
        this.panStart(e);
      });
      instance.on('touchstart', (e)=> {
        this.panStart(e);
      });
      instance.on('panend', (e)=> {
        this.panEnd(e);
      });
      instance.on('touchend', (e)=> {
        this.panEnd(e);
      });
      this.panzoomInstance = instance;
    },
    panStart(event){
      if(event.target&&(event.type=="mousedown"||event.type=="touchstart")) this.clickedNodeId = event.target.closest('.node-slot')? event.target.closest('.node-slot').getAttribute('id'):null;
        this.dragX = event.touches?event.touches[0].x:event.x;
        this.dragY = event.touches?event.touches[0].y:event.y;
    },
    panEnd(event){
      const dragEnd = {x: event.touches?event.touches[0].x:event.x, y: event.touches?event.touches[0].y:event.y}
      //if(this.panzoomInstance&&this.panzoomInstance.getTransform().scale<globals.minScale*2&&!this.elementIsInViewport(document.querySelector('.tree-view'),false))  this.scaleToFit(null,true);
      if(this.dragX==dragEnd.x&&this.dragY==dragEnd.y&&this.clickedNodeId !=null){
        this.toggleModal(this.tree_data.find(node=>node.id==this.clickedNodeId));
      }
      this.clickedNodeId = null;
    },
    toggleModal: function(node){
      EventBus.$emit('modal_departments_open', node)
    },
   scaleToFit(node=null, noZooming=false,firstScale=true){
      if(this.panzoomInstance==null) return;
      let oldScale = this.panzoomInstance?this.panzoomInstance.getTransform().scale:0.2;
      let newScale = noZooming?oldScale: 0.89*(node!=null? Math.min(document.querySelector('.vue-pan-zoom-item').clientWidth/document.querySelector('.background:nth-child('+(parseInt(node.domain=="regionaal"?0:1)+1)+')').clientWidth,document.querySelector('.vue-pan-zoom-item').clientHeight/document.querySelector('.background:nth-child('+(parseInt(node.domain=="regionaal"?0:1)+1)+')').clientHeight)
      :Math.min(document.querySelector('.vue-pan-zoom-item').clientWidth/[].slice.call(document.querySelectorAll('.background')).reduce((a,b)=>a.clientWidth+b.clientWidth+500),document.querySelector('.vue-pan-zoom-item').clientHeight/[].slice.call(document.querySelectorAll('.background')).reduce((a,b)=>a.clientHeight<b.clientHeight?b.clientHeight:a.clientHeight)));
      
      this.panzoomInstance.smoothMoveTo(0.575*document.querySelector('.vue-pan-zoom-scene').clientWidth, 0.35*document.querySelector('.vue-pan-zoom-scene').clientHeight);
      
      setTimeout(()=>this.panzoomInstance.smoothZoom(0.5*window.innerWidth, 0.5*window.innerHeight, (newScale - oldScale)/oldScale+1),20);
      
      if(firstScale) setTimeout(()=>this.scaleToFit(node||null, noZooming,false),1000);//;
      // it sometimes misses on first attempt, so 
    },
    elementIsInViewport(element,fullScreenSafe=false,scale=1){
      const rect = element.getBoundingClientRect();
      let offset = 200;
      return (
          rect.top >= 0-(fullScreenSafe?(window.innerHeight || document.documentElement.clientHeight)*0.8/scale:offset) &&
          rect.left >= 0-(fullScreenSafe?(window.innerWidth || document.documentElement.clientWidth)*0.8/scale:offset) &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)*(fullScreenSafe?1.9/scale:1.1) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)*(fullScreenSafe?1.9/scale:1.3)
      );
    },
    collapseExpandAll(expand=false,findNode=false){
      // check if unhiding the domain is needed
      if(findNode && globals.selectedId &&  globals.nodeList.find(node=>node.data.id == globals.selectedId)){
        let topTree = this.treeDataRaw.children.find(nd=>nd.treeId ==  globals.nodeList.find(node=>node.data.id == globals.selectedId).data.treeId)
        if(topTree && document.querySelector('.background-tree[data-domain="'+ topTree.domain+'"]').classList.contains('notvisible')) EventBus.$emit("unhide_domain",  topTree.domain);
      }
      setTimeout(()=>{
      // first click all topnodes to collapse/expand or click all bottom nodes their expand buttons
      let clickButtons = document.querySelectorAll((!expand?'.top .rich-media-node':'.rich-media-node')+':not(.notvisible) #expand'+(expand?':not(.expanded)':'.expanded'));
      if(clickButtons&&clickButtons.length>0){
        [].slice.call(clickButtons).forEach(e=>{if(e) e.click();});
        // next if it is a search for finding a node, repeat the procedure until it becomes visible
        if(findNode && globals.selectedId && globals.nodeList.find(node=>node.id == globals.selectedId)!=null && document.querySelector('.node-slot[id="'+globals.selectedId+'"]')==null) this.collapseExpandAll(true,true);
      }
    },300);
    },
    ui_event(e){
      console.log('ui_event: ' + e);
      let oldScale = this.panzoomInstance?this.panzoomInstance.getTransform().scale:0.2;
      switch (e) {
        case 'scale_to_fit':
          //scale_to_fit
          this.scaleToFit();
          break;
        case 'scale_to_fit_tree':
          //scale_to_fit
          this.scaleToFit(true);
          break;
        case 'scale_to_fit_noZoom':
          //scale_to_fit
          this.scaleToFit(null,false);
          break;
        case 'stop_zoom':
          clearInterval(this.zoomInt);
          break
        case 'zoom_in':
          if(this.panzoomInstance==null) break;
          var nsi = Math.pow(oldScale,1.1)+0.05;
          if(nsi<4){
            this.panzoomInstance.zoomTo(0.5*window.innerWidth, 0.5*window.innerHeight, (nsi - oldScale)/oldScale+1);
          }
          this.zoomInt = setInterval(()=>{
           var nsi = Math.pow(oldScale,1.1)+0.05;
          if(nsi<4){
            this.panzoomInstance.zoomTo(0.5*window.innerWidth, 0.5*window.innerHeight, (nsi - oldScale)/oldScale+1);
          }},50);
          break;
        case 'zoom_out':
          if(this.panzoomInstance==null) break;
          //zoom_out
          var nso = Math.pow(oldScale,0.9)-0.05;
          if(nso>0.1){
            this.panzoomInstance.zoomTo(0.5*window.innerWidth, 0.5*window.innerHeight, (nso - oldScale)/oldScale+1);
          }
         this.zoomInt = setInterval(()=>{
           var nso = Math.pow(oldScale,0.9)-0.05;
          if(nso>0.1){
            this.panzoomInstance.zoomTo(0.5*window.innerWidth, 0.5*window.innerHeight, (nso - oldScale)/oldScale+1);
          }},50);
          break;
        case 'show_relations':
          this.toggleInterlinks();
          break;
        case 'collapse_id':
          //collapse_all
          this.collapseExpandAll(true,true);
          break;
        case 'collapse_all':
          //collapse_all
          this.collapseExpandAll();
          break;
        case 'expand_all':
          //expand_all
          this.collapseExpandAll(true);
          break;
        default:
          break;
      }
    }
  }
}


</script>


<style scoped>
.container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rich-media-node{
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 8px;
  justify-content: center;
  background-color: #09f;
  box-sizing: border-box;
  user-select: none;
  font-size: 22px;
  cursor: pointer;

  /* overflow: hidden; */


  /*
  @Roberto: hier even pointer events weer aan voor klik modal test
  */
  /*
  pointer-events: none;
  */
}
.hidden + .overlay{
  height: 250px!important;
}
#expand{
  left: 38%;
  height: 30px;
  background: transparent;
  top: 73%;
  position: absolute;
  z-index: 100;
  margin: 10px;
  background: white;border-radius: 4px;
  padding: 4px 8px;
}
.button{
  cursor: pointer;
}
#modal{
  width: 400px;
  background: blue;
  color: white;
  position: fixed;
  top: 37%;
  left: 40%;
  z-index: 3;
  opacity: 1;
  padding: 20px;
  pointer-events: all;
}
#modal-backdrop{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  background-color: white;
  pointer-events: all;
}
.tree_main{
  background:radial-gradient(circle, rgba(167,203,202,1) 50%, rgba(167,203,202,0.7973564425770308) 50%);
}

#tree-controls{
  width: 100%;
  height: auto;
  border: 1px solid lightgrey;

  position: absolute; top:160px; left:20px; max-width: 200px;
  background: #fff;
}
#zoombuttons{
  float: right;
  position:relative
}
#zoombuttons span{
  border: 1px solid lightgrey;
}
</style>

