<template>
  <div class="pos-g z-20" id="modal_dynamic_wrap">
    <div
      class="relative flex items-center justify-center min-h-100vh w-full p-7"
    >
      <div
        class="pos-g w-full h-full bg-black bg-opacity-50"
        id="modal_dynamic_bg"
        @click="close"
      ></div>
      <div class="relative p-6 w-full max-w-1080" id="modal_dynamic_content">
        <div class="modal_wrapper p-6 bg-poppy text-white rounded-md shadow-md">
          <div class="flex flex-row-reverse">
            <div class="cursor-pointer" @click="close">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
          <div class="relative px-12 pb-8 mx640:px-2 mx640:pb-4">
            <h1 class="mb-10 mx640:text-3xl">{{ modal_title }}</h1>
            <div
              class="text-19 mb-10 leading-35 mx640:leading-26"
              v-html="modal_text"
            ></div>
            <div v-if="button_cookie_show">
              <div
                class="inline-block rounded border text-19 border-white py-2 px-12 cursor-pointer hover:bg-white hover:text-poppy transition duration-300 ease-in-out"
                @click="cookie_button"
              >
                Aan de slag
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { globals } from "@/modules/globals";
  import { EventBus } from "@/modules/event_bus";
  import api from "@/modules/api";
  import gsap from "gsap";

  var textsource;

  export default {
    name: "ModalDynamic",
    props: [],
    data() {
      return {
        modal_title: "",
        modal_text: "",
        button_cookie_show: false,
      };
    },
    mounted() {
      EventBus.$on("modal_dynamic_open", (e) => {
        this.populate(e);
      });
    },
    methods: {
      populate(e) {
        this.modal_title = "";
        if (e.title) {
          this.modal_title = e.title;
        }
        this.modal_text = "";
        if (e.text) {
          this.modal_text = e.text;
        }
        this.button_cookie_show = false;
        if (e.button_cookie_show) {
          this.button_cookie_show = true;
        }
        if (e.openened) {
          this.opened();
        } else {
          this.open();
        }
      },
      opened() {
        gsap.set("#modal_dynamic_content", { opacity: 1 });
        gsap.set("#modal_dynamic_wrap", { display: "block", opacity: 1 });
      },
      open() {
        gsap.to("#modal_dynamic_wrap", {
          duration: 0.5,
          opacity: 1,
          display: "block",
          ease: "power3.inOut",
        });
        gsap.to("#modal_dynamic_content", {
          delay: 0.3,
          duration: 0.5,
          opacity: 1,
          ease: "power3.inOut",
        });
      },
      close() {
        gsap.to("#modal_dynamic_content", {
          duration: 0.5,
          opacity: 0,
          ease: "power3.inOut",
        });
        gsap.to("#modal_dynamic_wrap", {
          delay: 0.3,
          duration: 0.5,
          opacity: 0,
          display: "none",
          ease: "power3.inOut",
        });
      },
      cookie_button() {
        api.call({
          task: "welcome_text_read",
          event: "welcome_text_read_done",
        });

        this.close();
      },
    },
  };
</script>

<style>
  #modal_dynamic_wrap {
    display: none;
    opacity: 0;
  }
  #modal_dynamic_content {
    opacity: 0;
  }
</style>
