<template>
  <div class="pos-g z-20" id="modal_departments_wrap">
    <div
      class="relative flex items-center justify-center min-h-100vh w-full p-7"
    >
      <div
        class="pos-g w-full h-full bg-black bg-opacity-50"
        id="modal_departments_bg"
        @click="close"
      ></div>
      <div
        class="relative p-6 w-full max-w-1080"
        id="modal_departments_content"
      >
        <div
          class="modal_wrapper bg-poppy text-white rounded-md shadow-md py-10"
        >
          <div class="flex flex-row-reverse department_section">
            <div class="cursor-pointer" @click="close">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
          <div class="relative">
            <div class="department_section">
              <h1 class="mx640:text-3xl mb-6">{{ modal_title }}</h1>
              <div
                class="text-19 leading-35 mx640:leading-26"
                v-html="modal_text"
              ></div>
            </div>
            <div
              class="department_wrapper department_section"
              v-if="modal_people.programmanager.length > 0"
            >
              <div class="uppercase text-sm py-10">
                programma manager
              </div>
              <div
                class="grid grid-cols-2 row-gap-8 col-gap-16 mx900:col-gap-20 mx900:grid-cols-1"
              >
                <div
                  v-for="(item, index) in modal_people.programmanager"
                  :key="index"
                >
                  <CardPerson :obj="contacts_data['person_' + item]" />
                </div>
              </div>
            </div>

            <div
              class="department_wrapper department_section"
              v-if="modal_people.voorzitters.length > 0"
            >
              <div class="uppercase text-sm py-10">
                voorzitters
              </div>
              <div
                class="grid grid-cols-2 row-gap-8 col-gap-16 mx900:col-gap-20 mx900:grid-cols-1"
              >
                <div
                  v-for="(item, index) in modal_people.voorzitters"
                  :key="index"
                >
                  <CardPerson :obj="contacts_data['person_' + item]" />
                </div>
              </div>
            </div>

            <div
              class="department_wrapper department_section"
              v-if="modal_people.secretarissen.length > 0"
            >
              <div class="uppercase text-sm py-10">
                secretarissen
              </div>
              <div
                class="grid grid-cols-2 row-gap-8 col-gap-16 mx900:col-gap-20 mx900:grid-cols-1"
              >
                <div
                  v-for="(item, index) in modal_people.secretarissen"
                  :key="index"
                >
                  <CardPerson :obj="contacts_data['person_' + item]" />
                </div>
              </div>
            </div>

            <div
              class="department_wrapper department_section"
              v-if="modal_people.deelnemers.length > 0"
            >
              <div class="uppercase text-sm py-10">
                deelnemers
              </div>
              <div
                class="grid grid-cols-2 row-gap-8 col-gap-16 mx900:col-gap-20 mx900:grid-cols-1"
              >
                <div
                  v-for="(item, index) in modal_people.deelnemers"
                  :key="index"
                >
                  <CardPerson :obj="contacts_data['person_' + item]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { globals } from "@/modules/globals";
  import { EventBus } from "@/modules/event_bus";
  import api from "@/modules/api";
  import gsap from "gsap";

  import CardPerson from "@/components/CardPerson.vue";

  var textsource;

  export default {
    name: "ModalDepartments",
    components: {
      CardPerson,
    },
    props: [],
    data() {
      return {
        modal_title: "",
        modal_text: "",
        modal_people: {
          voorzitters: [],
          secretarissen: [],
          deelnemers: [],
          programmanager: [],
        },
        contacts_data: {},
      };
    },
    mounted() {
      EventBus.$on("modal_departments_open", (e) => {
        if(!globals.dontOpenModal) this.populate(e);
      });
    },
    methods: {
      populate(e) {
        this.modal_title = "";
        if (e.title) {
          this.modal_title = e.title;
        }
        this.modal_text = "";
        if (e.text) {
          this.modal_text = e.text;
        }

        if (e.people) {
          this.modal_people = e.people;
        }

        this.contacts_data = globals.api_data.fetch_tree.contacts_data;

        console.log("... modal ...");
        console.log(e);
        console.log(this.contacts_data);

        this.open();
      },
      open() {
        gsap.to("#modal_departments_wrap", {
          duration: 0.5,
          opacity: 1,
          display: "block",
          ease: "power3.inOut",
        });
        gsap.to("#modal_departments_content", {
          delay: 0.2,
          duration: 0.5,
          opacity: 1,
          ease: "power3.inOut",
        });
      },
      close() {
        gsap.to("#modal_departments_content", {
          duration: 0.5,
          opacity: 0,
          ease: "power3.inOut",
        });
        gsap.to("#modal_departments_wrap", {
          delay: 0.2,
          duration: 0.5,
          opacity: 0,
          display: "none",
          ease: "power3.inOut",
        });
      },
    },
  };
</script>

<style>
  #modal_departments_wrap {
    display: none;
    opacity: 0;
  }
  #modal_departments_content {
    opacity: 0;
  }
  .department_wrapper:nth-child(odd) {
    background: rgba(255, 255, 255, 0.1);
  }
  .department_section {
    padding-right: 50px;
    padding-left: 50px;
  }
  .department_wrapper {
    padding-bottom: 45px;
  }
  /* .modal_wrapper {
    max-height: 740px;
    overflow: auto;
  } */
  @media screen and (max-width: 640px) {
    .department_section {
      padding-right: 25px;
      padding-left: 25px;
    }
    /* .modal_wrapper {
      max-height: 600px;
    } */
  }
</style>
