<template>
  <div
    class="relative flex items-center justify-center min-h-100vh w-full p-7 login-background"
  >
    <img
      src="@/assets/img/frame.png"
      alt=""
      class="absolute right-0 bottom-0"
    />
    <div class="p-6 w-full max-w-840 z-10 relative">
      <div
        class="bg-white rounded shadow-md text-center px-32 py-12 mx900:px-16 mx640:px-6 mx640:py-5"
      >
        <div class="">
          <img src="@/assets/img/mra_logo.png" alt="" class="mx-auto" />
        </div>
        <div class="mb-4">
          <h1 class="mx640:text-3xl">{{ intro_title }}</h1>
        </div>
        <div
          class="mb-10 text-16 mx640:text-base mx640:leading-26 text-left" style="line-height:1.7;"
          v-html="intro_text"
        ></div>
        <div class="a text-left">
          <div class="mb-2">
            <label for="email" class="uppercase text-xs">
              email adres
            </label>
          </div>
          <div class="mb-4">
            <input
              class="px-4 py-2 rounded border-2 border-galaxy w-full"
              id="email"
              ref="email"
              v-model="email"
              type="email"
            />
          </div>
          <div class="mb-2">
            <label for="email" class="uppercase text-xs">
              wachtwoord
            </label>
          </div>
          <div class="mb-4">
            <input
              class="px-4 py-2 rounded border-2 border-galaxy w-full"
              id="password"
              ref="password"
              v-model="password"
              type="password"
            />
          </div>
          <div
            class="mt-6 rounded border border-poppy p-6 text-poppy mx640:text-base mx640:p-3"
            v-show="error_show"
          >
            {{ error_message }}
          </div>
          <div class="pb-6"></div>
        </div>
        <div class="mb-6">
          <router-link
            to="wachtwoord-vergeten"
            class="cursor-pointer text-19 underline hover:text-poppy transition duration-300 ease-in-out mx640:text-base"
            >Wachtwoord vergeten?</router-link
          >
        </div>
        <div class="relative">
          <button
            type="button"
            class="py-2 px-12 text-galaxy text-19 rounded inline-block border-2 border-galaxy hover:bg-galaxy hover:text-white transition duration-300 ease-in-out"
            @click="login"
          >
            Log in
          </button>
          <div
            class="pos-g bg-white bg-opacity-50"
            v-show="click_disabled"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/modules/api";
  import { globals } from "@/modules/globals";
  import { EventBus } from "@/modules/event_bus";
  var formIssues = {
    user_not_found: {
      field: "email",
      message: "E-mail of wachtwoord onjuist",
    },
    password_incorrect: {
      field: "email",
      message: "E-mail of wachtwoord onjuist",
    },
  };

  export default {
    name: "Login",
    props: [],
    data() {
      return {
        intro_title: globals.api_data.fetch_intro["inlog-scherm"].title,
        intro_text: globals.api_data.fetch_intro["inlog-scherm"].text,
        click_disabled: false,
        email: "",
        password: "",
        error_message: "",
        error_show: false,
      };
    },
    mounted() {
      EventBus.$on("data_login", (e) => {
        this.dataLoaded(e);
      });
    },
    methods: {
      login() {
        console.log("login");
        this.click_disabled = true;

        var formData = {
          email: this.email,
          password: this.password,
          remember: 1,
        };

        api.call({
          task: "login",
          event: "data_login",
          global: false,
          formData: formData,
        });
      },
      dataLoaded(e) {
        console.log(e);

        if (e.result == "nok") {
          this.formError(e.data.respond);
          return;
        }

        console.log("login success");

        globals.loggedIn = true;
        EventBus.$emit("login_success");
      },
      formError(e) {
        this.error_message = formIssues[e].message;
        this.error_show = true;
        this.click_disabled = false;
      },
    },
  };
</script>

<style></style>
