<template>
  <div class="pos-g">

    <Login v-if="!loggedIn && !loggedOut && loaded" />

    <LoggedOut v-if="loggedOut && loaded" />

    <div v-if="loggedIn && loaded">
      <TreeView v-if="loggedIn" />
      <HeaderMain />
      <TreeUI />
    </div>

    <ModalDynamic />
    <ModalDepartments />

    <div id="curtain" class="fixed top-0 left-0 w-full min-h-100vh bg-white z-30">
      <div class="pos-center" id="curtain_img">
        <img src="@/assets/img/mra_logo.png" />
      </div>
    </div>

  </div>
</template>

<script>
import * as cheet from 'cheet.js'
import { globals } from "@/modules/globals";
import { EventBus } from '@/modules/event_bus'
import api from '@/modules/api'

import gsap from 'gsap'

import Login from "@/components/Login.vue";
import LoggedOut from "@/components/LoggedOut.vue";
import HeaderMain from "@/components/HeaderMain.vue";
import TreeUI from "@/components/TreeUI.vue";
import ModalDynamic from "@/components/ModalDynamic.vue";
import ModalDepartments from "@/components/ModalDepartments.vue";
import TreeView from "@/components/TreeView.vue";


export default {
  name: "MainRoute",
  components: {
    Login,
    LoggedOut,
    HeaderMain,
    TreeUI,
    ModalDynamic,
    ModalDepartments,
    TreeView,
  },
  props: [],
  data() {
    return {
      loaded: false,
      loggedIn: false,
      loggedOut: false
    }
  },
  mounted() {
    console.log('MainRoute')
    if(globals.loggedIn){
      this.getTreeData()
    }else{
      this.loaded = true
      gsap.delayedCall(1, this.curtain_open)
    }
    EventBus.$on('login_success', (e) => {
      this.getTreeData()
    })
    EventBus.$on('logout_success', (e) => {
      this.curtain_down()
      gsap.delayedCall(1, this.logout_done)
    })
    EventBus.$on('goToLogin', (e) => {
      this.curtain_down()
      gsap.delayedCall(1, this.gotologin_done)
    })
  },
  methods: {
    getTreeData(){

      api.call({
        task: 'fetch_tree',
        event: 'fetch_tree_done',
        global: true,
      })
      EventBus.$on('fetch_tree_done', (e) => {
        console.log('===================== fetched tree data')
        console.log(e)
     
        /*
        this.$nextTick(function () {
          this.modal_welcome()
        })
        */

        this.curtain_down()
        gsap.delayedCall(0.8, this.enter_the_tree)
      })
      
    },
    enter_the_tree(){
      this.modal_welcome()
      this.loggedIn = true
      this.loaded = true
      gsap.delayedCall(1.8, this.curtain_open)
      //this.curtain_open()
    },
    logout_done(){
      this.loggedOut = true
      this.loggedIn = false
      this.curtain_open()
    },
    gotologin_done(){
      this.loggedOut = false
      this.curtain_open()
    },
    modal_welcome(){
      if(globals.api_data.fetch_tree.welcome_text_read == 'no'){
        globals.api_data.fetch_tree.texts['welkom'].button_cookie_show = true
        globals.api_data.fetch_tree.texts['welkom'].openened = true
        EventBus.$emit('modal_dynamic_open', globals.api_data.fetch_tree.texts['welkom'])
      }
    },
    curtain_down(){
      gsap.to('#curtain',{duration:0.4, opacity:1, display:'block', ease: 'power3.inOut'})
      gsap.to('#curtain_img',{delay:0.3, duration:0.4, opacity:1, scale:1, display:'block', ease: 'power3.inOut'})
    },
    curtain_open(){
      gsap.to('#curtain_img',{duration:0.4, opacity:0, display:'none', scale:0.95, ease: 'power3.inOut'})
      gsap.to('#curtain',{delay:0.3, duration:0.4, opacity:0, display:'none', ease: 'power3.inOut'})
    }
  }
}

 
cheet('m o d a l', function () {
    EventBus.$emit('modal_departments_open', globals.api_data.fetch_tree.texts['info'])
})



</script>

<style>

</style>

