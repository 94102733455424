<template>
  <div
    class="relative flex items-center justify-center min-h-100vh w-full p-7 login-background"
  >
    <img
      src="@/assets/img/frame.png"
      alt=""
      class="absolute right-0 bottom-0"
    />
    <div class="p-6 w-full max-w-840 z-10 relative">
      <div
        class="p-6 bg-white rounded shadow-md text-center px-32 py-12 mx900:px-16 mx640:px-6 mx640:py-5"
      >
        <div v-if="loading">
          <div>
            <div class="mr-4 inline-block align-top opacity-50">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </div>
            <div
              class="text-2xl inline-block leading-35 mx640:text-base mx640:leading-26"
            >
              Account controleren
            </div>
          </div>
        </div>

        <div v-if="valid && !loading">
          <div class="mb-10">
            <div>
              <div class="mr-4 inline-block align-top opacity-50">
                <svg
                  class="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              </div>
              <h1 class="inline-block mx640:text-3xl mb-5">
                Je account is geactiveerd.
              </h1>
            </div>
            <div
              class="text-2xl mb-10 leading-35 mx640:text-base mx640:leading-26"
            >
              Je kunt nu inloggen met het wachtwoord dat je in de email
              toegestuurd hebt gekregen.<br />
              Wil je het wachtwoord veranderen?<br />
              Klik dan bij het inlogscherm op 'wachtwoord vergeten' om een nieuw
              wachtwoord aan te vragen.
            </div>
          </div>
          <router-link
            to="/"
            class="py-2 px-12 rounded inline-block text-galaxy border-2 border-galaxy hover:bg-galaxy hover:text-white transition duration-300 ease-in-out "
            >inloggen</router-link
          >
        </div>

        <div v-if="invalid && !loading">
          <div class="text-lg mb-10">
            <div class="mr-4 inline-block align-top opacity-50">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                ></path>
              </svg>
            </div>
            <div
              class="text-2xl mb-4 inline-block leading-35 mx640:text-base mx640:leading-26"
            >
              Deze pagina is ongeldig.
            </div>
          </div>
          <router-link
            to="/"
            class="text-galaxy text-19 cursor-pointer underline mx640:text-base hover:text-poppy transition duration-300 ease-in-out"
            >naar de voorpagina</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/modules/api";
  import { globals } from "@/modules/globals";
  import { EventBus } from "@/modules/event_bus";

  export default {
    name: "AccountActivate",
    props: [],
    data() {
      return {
        loading: true,
        valid: false,
        invalid: false,
      };
    },
    mounted() {
      var salt = this.$route.params.salt;
      api.call({
        task: "account_verify",
        salt: salt,
        global: false,
        event: "data_account_verify",
      });
      EventBus.$on("data_account_verify", (e) => {
        this.dataLoaded(e);
      });
    },
    methods: {
      dataLoaded(d) {
        if (d.result != "ok") {
          this.loading = false;
          this.invalid = true;
        }
        if (d.result == "ok") {
          this.loading = false;
          this.valid = true;
        }
      },
    },
  };
</script>

<style></style>
