<template>
  <div class="flex mx480:block">
    <div class="mr-5 mx480:mb-4">
      <div v-if="obj.photo">
        <div
          class="rounded-full w-20 h-20 bg-gray-100 bg-center bg-cover"
          :style="{ backgroundImage: 'url(' + obj.photo.xs + ')' }"
        ></div>
      </div>
      <div v-if="!obj.photo">
        <div
          class="rounded-full w-20 h-20 bg-gray-100 bg-center bg-cover relative overflow-hidden"
        >
          <div class="pos-center text-gray-700">
            <svg
              class="w-10 h-10"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="text-2xl pb-2 mx640:text-xl mx640:leading-26" v-if="obj.name">
        {{ obj.name }}
      </div>
      <div
        class="text-19 pb-4 mx640:text-base leading-26"
        v-if="obj.infotext"
        v-html="obj.infotext"
      ></div>
      <div class="text-19 pb-4 mx640:text-xs leading-26" v-if="obj.mobile">
        <div class="flex">
          <div class="mr-2">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
              ></path>
            </svg>
          </div>
          <div class="underline">
            {{ obj.mobile }}
          </div>
        </div>
      </div>
      <div class="text-19 pb-4 mx640:text-xs leading-26" v-if="obj.telephone">
        <div class="flex">
          <div class="mr-2">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              ></path>
            </svg>
          </div>
          <div class="underline">
            {{ obj.telephone }}
          </div>
        </div>
      </div>
      <div class="text-19 pb-4 mx640:text-xs leading-26" v-if="obj.email">
        <div class="flex">
          <div class="mr-2">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
              ></path>
            </svg>
          </div>
          <div>
            <a class="underline" :href="'mailto:' + obj.email">{{
              obj.email
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CardPerson",
    props: ["obj"],
    data() {
      return {};
    },
    mounted() {},
    methods: {},
  };
</script>

<style></style>
