<template>
  <div
    class="fixed w-full h-111p bg-white shadow-lg flex p-4 items-center z-10 mx640:justify-between"
  >
    <div class="mx640:order-2">
     <img src="@/assets/img/mra_logo.png" class="w-full" />
    </div>
    <div class="ml-auto mx640:hidden"></div>
    <div
      class="mr-10 w-16 relative hover:text-poppy transition duration-300 ease-in-out mx640:order-1"
    >
      <div class="text-center">
        <div class="inline-block">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </div>
      </div>
      <div class="text-center">
        <div class="inline-block">
          INFO
        </div>
      </div>
      <div class="pos-g cursor-pointer" @click="modal_info_open"></div>
    </div>
    <div
      class="py-2 px-12 text-black text-19 cursor-pointer rounded inline-block border-2 border-black hover:bg-poppy hover:text-white hover:border-transparent transition duration-300 ease-in-out mx640:hidden"
      @click="logout"
    >
      Uitloggen
    </div>
    <div
      class="pl-5 text-black text-base cursor-pointer underline inline-block hover:text-poppy transition duration-300 ease-in-out order-3 mn640:hidden"
      @click="logout"
    >
      Uitloggen
    </div>
  </div>
</template>

<script>
  import { globals } from "@/modules/globals";
  import { EventBus } from "@/modules/event_bus";

  export default {
    name: "HeaderMain",
    props: [],
    data() {
      return {};
    },
    mounted() {},
    methods: {
      logout() {
        globals.loggedIn = false;
        EventBus.$emit("logout_success");
      },
      modal_info_open() {
        EventBus.$emit(
          "modal_dynamic_open",
          globals.api_data.fetch_tree.texts["info"]
        );
      },
    },
  };
</script>

<style></style>
