<template>
  <div class="relative flex items-center justify-center min-h-100vh w-full p-7 bg-gray-400 login-background">
    <img
      src="@/assets/img/frame.png"
      alt=""
      class="absolute right-0 bottom-0"
    />
    <div class="p-6 w-full max-w-840 z-10 relative">
      <div class="p-6 bg-white rounded shadow-md text-center px-32 py-12 mx900:px-16 mx640:px-6 mx640:py-5"> 

          <h1 class="mb-4 mx640:text-3xl">Wachtwoord herstellen</h1>

          <div v-show="!password_sent">
            <div class="text-2xl mb-10 leading-35 mx640:text-base mx640:leading-26" v-html="password_reset_text">
            </div>
            <div class="text-left">
              <div class="mb-2">
                <label for="password" class="uppercase text-xs">
                  wachtwoord
                </label>
              </div>
              <div class="mb-4">
                <input class="px-4 py-2 rounded border-2 border-galaxy w-full" id="password" ref="password" v-model="password" type="password">
              </div>
              <div class="mb-2">
                <label for="password_again" class="uppercase text-xs">
                  wachtwoord normaals
                </label>
              </div>
              <div class="mb-4">
                <input class="px-4 py-2 rounded border-2 border-galaxy w-full" id="password_again" ref="password_again" v-model="password_again" type="password">
              </div>
              <div class="mt-6 rounded border border-poppy p-6 text-poppy mx640:text-base mx640:p-3" v-show="error_show">
                {{error_message}}
              </div>
              <div class="pb-6"></div>
            </div>
            <div class="relative">
              <button type="button" class="py-2 px-12 text-galaxy text-19 rounded inline-block border-2 border-galaxy hover:bg-galaxy hover:text-white transition duration-300 ease-in-out" @click="send">verstuur</button>
              <div class="pos-g bg-white bg-opacity-50" v-show="click_disabled"></div>
            </div>
          </div> 

          <div v-show="password_sent">
            <div class="text-2xl mb-10 leading-35 mx640:text-base mx640:leading-26" v-html="password_reset_text_sent">
            </div>
            <router-link to="/" class="cursor-pointer text-19 underline hover:text-poppy transition duration-300 ease-in-out mx640:text-base">inloggen</router-link>
          </div>   

      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api'
import { globals } from "@/modules/globals";
import { EventBus } from '@/modules/event_bus'

var formIssues = {
  'salt_no_match': {
    field: 'password',
    message: 'Ongeldige actie'
  },
  'salt_not_valid': {
    field: 'password',
    message: 'Ongeldige actie'
  },
  'date_expired': {
    field: 'password',
    message: 'Deze link is niet meer geldig'
  },
  'password_empty': {
    field: 'password',
    message: 'Ongeldige wachtwoorden'
  },
  'password_nomatch': {
    field: 'password',
    message: 'De wachtwoorden komen niet overeen'
  },
  'password_strength': {
    field: 'password',
    message: 'Wachtwoord is niet sterk genoeg.\n\rGebruik tenminste 8 karakters met minimaal 1 hoofdletter en 1 kleine letter.'
  }
}

var salt

export default {
  name: "PasswordReset",
  props: [],
  data() {
    return {
      password_reset_text: 'Vul hieronder je nieuwe wachtwoord tweemaal in.',
      password_reset_text_sent: 'Je wachtwoord is gewijzigd. Log opnieuw in.',
      click_disabled: false,
      password: '',
      password_again: '',
      error_message: '',
      error_show: false,
      password_sent: false
    }
  },
  mounted() {

    salt = this.$route.params.salt

    EventBus.$on('data_password_reset', (e) => {
      this.dataLoaded(e)
    })    
  },
  methods: {
    send(){
      this.click_disabled = true

      var formData = {
        password: this.password,
        password_again: this.password_again,
        salt: salt
      }
      api.call({
        task: 'password_reset',
        event: 'data_password_reset',
        global: false,
        formData: formData
      })
    },
    dataLoaded(e){
      console.log(e)

      if(e.result == 'nok'){
        this.formError(e.data.respond)
        return
      }   
      
      console.log('password_reset success')
      this.password_sent = true

    },
    formError(e){
      this.error_message = formIssues[e].message
      this.error_show = true
      this.click_disabled = false
    }
  },
}
</script>

<style>

</style>

