<template>
  <div class="fixed w-full h-full top-0 left-0 pointer-events-none padding_ui">
    <div class="relative w-full h-full">
      <div class="tree_ui_part tree_ui_searchbar_wrapper">
        <div class="flex items-center bg-white tree_ui_searchbar">
          <div class="mr-2">
            <svg
              class="w-6 h-6 transition duration-300 ease-in-out"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
        <div class="h-8 border-r-2 mr-2 border-spirit"></div>
        <div class="flex-grow">
          <input
            class="w-full py-1 px-2 focus:outline-none"
            type="text"
            value=""
            @keyup="search($event)"
            placeholder="Zoek op een persoon of afdeling"
          />
        </div>
        </div>
        <div id="search_results" class="bg-white">
          <div class="persons" v-if="search_results&&search_results[0].length>0">
            <div class="row" v-for="(pers,index) of search_results[0]" :key="index" @click="modalClick(pers.department)" @mouseenter="highlight(pers.department.id)" @mouseleave="unhighlight()"><span class="inline-block"><span class="material-icons-outlined mr-2 hidden" style="top: 6px;position: relative; color:#afafaf;">person</span>{{pers.name}} - {{pers.department.title}}</span></div>
          </div>
          <div class="departments" v-if="search_results&&search_results[1].length>0">
            <div class="row" v-for="(dep,index) of search_results[1]" :key="index" @click="modalClick(dep)" @mouseenter="highlight(dep.id)" @mouseleave="unhighlight()"><span class="inline-block"><span class="material-icons-outlined mr-2 hidden" style="top: 6px;position: relative; color:#afafaf;">apartment</span>{{dep.title}}</span></div>
          </div>
        </div>
      </div>

      <div class="tree_ui_part tree_ui_toggles mx640:hidden">
        <div class="tree_ui_area">
          <div
            class="flex items-center cursor-pointer hover:text-poppy transition duration-300 ease-in-out"
            @click="ui_click('expand_all')"
          >
            <div class="mr-2">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </div>
            <div class="flex-grow text-xs uppercase pr-2">
              <div class="">
                alles uitklappen
              </div>
            </div>
          </div>
          <div class="h-0 mt-2 mb-2 border-b "></div>
          <div
            class="flex items-center cursor-pointer hover:text-poppy transition duration-300 ease-in-out"
            @click="ui_click('collapse_all')"
          >
            <div class="mr-2">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </div>
            <div class="flex-grow text-xs uppercase pr-2" >
              <div class="">
                alles inklappen
              </div>
            </div>
          </div>
        </div>
        <!--div class="tree_ui_area mt-3">
          <div
            class="relation_wrapper flex items-center hover:text-poppy transition duration-300 ease-in-out cursor-pointer" @click="ui_click('show_relations')"
          >
            <div class="mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                height="14"
                viewBox="0 0 46 14"
                width="46"
                stroke="#000"
                class="transition duration-300 ease-in-out"
              >
                <g stroke-width="1.5">
                  <path d="m14 7.5h18" stroke-dasharray="2 2" />
                  <g fill="#fff">
                    <circle cx="7" cy="7" r="6.25" />
                    <circle cx="39" cy="7" r="6.25" />
                  </g>
                </g>
              </svg>
            </div>
            <div class="flex-grow text-xs uppercase pr-2">
              <div >
                relaties tonen
              </div>
            </div>
          </div>
        </div-->
         <!--div class="tree_ui_area mt-3">
          <div
            class="relation_wrapper flex items-center"
          > 
            <div class="w-full uppercase text-xs">Helpers</div>

            <div class="mr-2 smart-zoom hover:text-poppy transition duration-300 ease-in-out cursor-pointer" @click="toggleAutoZoom">
             <span class="material-icons-outlined">animation</span>
            <div class="flex-grow text-xs uppercase pr-2">
              <div>
                zoom
              </div>
            </div>
            </div>
            <div class="mr-2 smart-zoom hover:text-poppy transition duration-300 ease-in-out cursor-pointer" @click="toggleAutoZoom">
             <span class="material-icons-outlined">animation</span>
            <div class="flex-grow text-xs uppercase pr-2">
              <div>
                zoom
              </div>
            </div>
            </div>
          </div>
        </div-->
      </div>

      <div class="tree_ui_part tree_ui_controls">
        <div class="tree_ui_area mt-3 mx640:hidden">
          <div
            class="cursor-pointer hover:text-poppy transition duration-300 ease-in-out"
            @mousedown="ui_click('zoom_in')" @mouseup="ui_click('stop_zoom')" @touchstart="ui_click('zoom_in')" @touchend="ui_click('stop_zoom')"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              ></path>
            </svg>
          </div>
          <div class="h-0 mt-2 mb-2 border-b "></div>
          <div
            class="cursor-pointer hover:text-poppy transition duration-300 ease-in-out"
            @mousedown="ui_click('zoom_out')" @mouseup="ui_click('stop_zoom')" @touchstart="ui_click('zoom_out')" @touchend="ui_click('stop_zoom')"
          > 
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M20 12H4"
              ></path>
            </svg>
          </div>
        </div>
        <div class="tree_ui_area mt-3">
          <div class="cursor-pointer" id="scaletofit" @click="ui_click('scale_to_fit')">
            <svg
              style="fill: rgb(255, 255, 255); stroke-width: 25px;"
              stroke="#000"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 500 500"
              class="full_screen transition duration-300 ease-in-out"
            >
              <rect x="2.366" y="1.853" width="496.524" height="496.117" />
              <line x1="429.634" y1="75.446" x2="285.835" y2="219.044" />
              <line x1="216.705" y1="290.124" x2="68.509" y2="433.491" />
              <line x1="429.483" y1="65.481" x2="429.483" y2="159.969" />
              <line x1="442.269" y1="75.466" x2="339.419" y2="75.466" />
              <line x1="158.238" y1="434.039" x2="55.388" y2="434.039" />
              <line x1="68.247" y1="351.187" x2="68.247" y2="445.674" />
            </svg>
          </div>
        </div>
        <div class="tree_ui_area mt-3 mn640:hidden">
          <div class="cursor-pointer" @click="openMoreOption">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
            </svg>
          </div>
        </div>
      </div>

      <div class="tree_ui_part tree_ui_area tree_ui_legend" style="z-index:-1;">
        <div
          class="flex items-center cursor-pointer hover:text-poppy" @click="legendHandler"
        >
          <div class="flex-grow text-xs uppercase transition duration-300 ease-in-out">
            legenda
          </div>
          <div class="ml-auto"></div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="legend_caret h-6 w-6 transition duration-300 ease-in-out"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
        <div class="legend_items_wrapper mt-6">
          <!-- <div v-for="item in legendItems" :key="item.id" class="flex items-center mb-3" @mouseenter="highlightLegend(item.id)" @mouseleave="unhighlight()">
            <span class="mr-3" v-html="item.shape"></span>
            <span class="text-xs">{{item.title}}</span>
          </div> -->
          <div v-for="(item) in blocktypes_data" :key="item.id" class="flex items-center mb-3" @mouseenter="highlightLegend(item.id)" @mouseleave="unhighlight()">
            
            <span v-if="item.color !== '#FFFFFF'" class="mr-3 h-6 w-10" :style="{background : item.color}"></span>
             <!-- 
            <span v-if="i>blocktypes_data.length-3" class="mr-3 h-1 w-10" :style="{height:'2px',border : '1px '+(i==blocktypes_data.length-2?'solid ':'dashed ')+item.color}"></span>
            <span v-else-if="item.color !== '#FFFFFF'" class="mr-3 h-6 w-10" :style="{background : item.color}"></span>
              -->
            <span v-else class="mr-3 h-6 w-10 border-2 border-galaxy"></span>
            <span class="text-xs uppercase">{{item.title}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tree_ui_part more_options bg-white w-full absolute bottom-0 pt-10 pb-6 pl-5 pr-12 z-10">
      <div class="more_options_close absolute cursor-pointer">
        <svg @click="closeMoreOption" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </div>
      <div class="grid grid-cols-2 row-gap-10">
        <div
          class="cursor-pointer text-center"
          @click="ui_click('expand_all')"
        >
          <svg
            class="w-6 h-6 mx-auto mb-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div class="flex-grow text-xs uppercase pr-2">
            <div class="">
              alles uitklappen
            </div>
          </div>
        </div>
        <div
          class="cursor-pointer text-center"
          @click="ui_click('collapse_all')"
        >
          <svg
            class="w-6 h-6 mx-auto mb-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div class="flex-grow text-xs uppercase pr-2" >
            <div class="">
              alles inklappen
            </div>
          </div>
        </div>
        <div class="cursor-pointer text-center" @click="ui_click('scale_to_fit')">
          <svg
            style="fill: rgb(255, 255, 255); stroke-width: 25px;"
            stroke="#000"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 500"
            class="w-6 h-6 mx-auto mb-4"
          >
            <rect x="2.366" y="1.853" width="496.524" height="496.117" />
            <line x1="429.634" y1="75.446" x2="285.835" y2="219.044" />
            <line x1="216.705" y1="290.124" x2="68.509" y2="433.491" />
            <line x1="429.483" y1="65.481" x2="429.483" y2="159.969" />
            <line x1="442.269" y1="75.466" x2="339.419" y2="75.466" />
            <line x1="158.238" y1="434.039" x2="55.388" y2="434.039" />
            <line x1="68.247" y1="351.187" x2="68.247" y2="445.674" />
          </svg>
          <div class="flex-grow text-xs uppercase pr-2" >
            <div class="">
              Passend maken
            </div>
          </div>
        </div>
        <div class="cursor-pointer text-center" @click="ui_click('show_relations')">
          <div class="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="14"
              viewBox="0 0 46 14"
              width="46"
              stroke="#000"
              class="mx-auto mb-6"
            >
              <g stroke-width="1.5">
                <path d="m14 7.5h18" stroke-dasharray="2 2" />
                <g fill="#fff">
                  <circle cx="7" cy="7" r="6.25" />
                  <circle cx="39" cy="7" r="6.25" />
                </g>
              </g>
            </svg>
          </div>
          <div class="flex-grow text-xs uppercase pr-2">
            <div >
              relaties tonen
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { globals } from "@/modules/globals";
  import { EventBus } from "@/modules/event_bus";
  import gsap from "gsap";


  export default {
    name: "TreeUI",
    props: [],
    data() {
      return { 
        int:null,
        interlinkStateChanged:false,
        //blocktypes_data : globals.api_data.fetch_tree.types_data,
        search_results:globals&&globals.search_results||null,
        isMoreOptionOpen: false,
        blocktypes_data: null/*.concat([{
            id:"6",
            color:"#DA333B",
            title: "ITEM",
          },
          {
            id:"7",
            color:"#DA333B",
            title: "ITEM",
          }]) [
          {
            id:0,
            color:"#123123",
            shape: "<svg xmlns='http://www.w3.org/2000/svg' height='26' viewBox='0 0 39 26' width='39'><path d='m0 0h39v26h-39z' fill='#000'/></svg>",
            title: "BESTUURSNIVEAU",
          },
          {
            id:1,
            color:"#342512",
            shape: "<svg xmlns='http://www.w3.org/2000/svg' height='26' viewBox='0 0 39 26' width='39'><path d='m0 0h39v26h-39z' fill='#889483'/></svg>",
            title: "DIRECTEURENNNIVEAU",
          },
          {
            id:2,
            color:"#652353",
            shape: "<svg xmlns='http://www.w3.org/2000/svg' height='26' viewBox='0 0 39 26' width='39'><path d='m0 0h39v26h-39z' fill='#85B4AE'/></svg>",
            title: "MANAGEMENTNIVEAU",
          },
          {
            id:3,
            color:"#734523",
            shape: "<svg xmlns='http://www.w3.org/2000/svg' height='26' viewBox='0 0 39 26' width='39'><path d='m0 0h39v26h-39z' fill='#C29624'/></svg>",
            title: "AMBTELIJK NIVEAU",
          },
          {
            id:4,
            color:"#faa231",
            shape: "<svg xmlns='http://www.w3.org/2000/svg' height='26' viewBox='0 0 39 26' width='39' fill='none'><rect x='1' y='1' width='37' height='24' stroke='#85B4AE' stroke-width='2'/></svg>",
            title: "PROJECT/WERKLIJN",
          },
          {
            id:5,
            color:"#435031",
            shape: "<svg xmlns='http://www.w3.org/2000/svg' height='26' viewBox='0 0 39 26' width='39' fill='none'><path d='M0 13H39' stroke='#DA333B' stroke-width='2'/></svg>",
            title: "ITEM",
          },
          {
            id:6,
            color:"#124567",
            shape: "<svg xmlns='http://www.w3.org/2000/svg' height='26' viewBox='0 0 39 26' width='39' fill='none'><path d='M0 13H39' stroke='#DA333B' stroke-width='2' stroke-dasharray='4 4'/></svg>",
            title: "ITEM",
          },
        ] */
      };
    },
    mounted() {
      this.search_results=globals&&globals.search_results||null
      this.int = setInterval(()=>{
        if(globals.nodeList!=null){
          
          this.blocktypes_data = Object.values(globals.api_data.fetch_tree.types_data).filter(type=>globals.nodeList.filter(node=>node.data.blocktype==type.id).length>0).map(type=>{
            return {id:type.id,title:type.title,color:type.color}
          })
           
        clearInterval(this.int);
      }},300)

      console.log('====================== dynamic block types')
      console.log(this.blocktypes_data)
      
      this.legendTl = gsap.timeline({reversed: true})
          .to(".tree_ui_legend", {height: "auto"})
          .from(".legend_items_wrapper", {autoAlpha:0, x:50})
          .to(".legend_caret", {rotation: 180},'0')
    },
    methods: {
      elementIsInViewport(element){
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      },
      toggleAutoZoom(){
        globals.smartZoom = !globals.smartZoom;
        if(globals.smartZoom) document.getElementsByClassName('smart-zoom')[0].style.color = 'rgba(218, 51, 59, 0.8)'
        else document.getElementsByClassName('smart-zoom')[0].style.color = null;
      },
      highlight(depId){
        document.getElementsByClassName('dom-container')[0].classList.add('highlightmode');
        let nodeToHighlight = document.querySelector('.node-slot[id="'+depId+'"]');
        // when the node is collapsed
        if(nodeToHighlight==null){
          globals.selectedId = depId;
          setTimeout(()=>{
            this.ui_click('collapse_id');
            setTimeout(()=>{
              let nodeToHighlight = document.querySelector('.node-slot[id="'+depId+'"]');
              if(nodeToHighlight!=null) nodeToHighlight.classList.add('highlighted');
            },300);
          },300);
        }else{
          nodeToHighlight.classList.add('highlighted');
          // when the node is out of view
        }
      },
      unhighlight(){
        document.getElementsByClassName('dom-container')[0].classList.remove('highlightmode');
        if(this.interlinkStateChanged) document.getElementsByTagName('svg')[0].classList.toggle('showinterlinks');
        this.interlinkStateChanged = false;
        document.getElementsByClassName('svg')[0].classList.remove('highlightlinks');
        document.getElementsByClassName('svg')[0].classList.remove('highlightinterlinks');
        [].slice.call(document.querySelectorAll('.node-slot.highlighted')).forEach(el=>el.classList.remove('highlighted'));
      },
      highlightLegend(typeId){
        // first fade everything in the nodes container
        document.getElementsByClassName('dom-container')[0].classList.add('highlightmode');

        // when a line is highlighted also fade the other type of line
        if(parseInt(typeId)>=99999999999998){
          document.getElementsByClassName('svg')[0].classList.add(parseInt(typeId)==99999999999998?'highlightlinks':'highlightinterlinks');
          if(parseInt(typeId)==99999999999999 && !document.getElementsByTagName('svg')[0].classList.contains('showinterlinks')){
            this.interlinkStateChanged = true;
            document.getElementsByClassName('svg')[0].classList.add('showinterlinks');
          }
        } else
        // when a node type is highlighted then highlight this node type
        [].slice.call(document.querySelectorAll('.node-slot[typeId="'+typeId+'"]')).forEach(el=>el.classList.add('highlighted'));
      },
      modalClick(dep){
        EventBus.$emit('modal_departments_open', dep);
      },
      legendHandler(){
        this.legendTl.reversed()? this.legendTl.play() :this.legendTl.reverse();
      },
      search(evt) {
        EventBus.$emit("search", evt.target.value);
        setTimeout(()=>this.search_results=globals&&globals.search_results||null,500)
      },
      ui_click(who) {
        EventBus.$emit("ui_click", who);
      },
      openMoreOption(){
        const t1 = gsap.timeline()
        t1.to(".more_options",{ opacity: 1,display:"block", height: "210px",ease: "power3.inOut"})
      },
      closeMoreOption(){
        const t1 = gsap.timeline()
        t1.to(".more_options",{ opacity: 0,display:"none",height: 0,ease: "power3.inOut"})
      }
    },
  };
</script>

<style>
  .padding_ui {
    padding: 30px;
    padding-top: 140px;
  }
  .tree_ui_part {
    position: absolute;
    pointer-events: all;
  }
  .tree_ui_area {
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  }
  .tree_ui_searchbar {
    border-radius: 4px;
    padding: 0 15px;
    margin-bottom: 5px;
    height:50px;
  }
  #search_results {
    border-radius: 4px;
    padding: 0 15px;
    max-height: 500px;
    overflow: auto;
  }
  .tree_ui_searchbar_wrapper {
    top: 0px;
    left: 0px;
    width: 100%;
    max-width: 380px;
  }
  
  .tree_ui_toggles {
    top: 0px;
    right: 0px;
  }
  .tree_ui_controls {
    bottom: 0px;
    right: 0px;
    width: 45px;
  }
  .tree_ui_legend {
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 44px;
    overflow: hidden;
    max-width: 222px;
  }
  .full_screen:hover {
    stroke: #da333b;
  }
  .tree_ui_searchbar:hover > * svg {
    stroke: #da333b;
  }
  .relation_wrapper:hover > * svg {
    stroke: #da333b;
  }
   #search_results > div{
    padding:20px 0;
  }
  #search_results .persons{
    border-bottom: 1px solid lightgrey;
  }
  #search_results > div > div{
    height:50px;
  }
  #search_results{
    height:0;
    overflow: hidden;
  }
  .tree_ui_searchbar_wrapper:hover #search_results{
    height:auto!important;
  }
  @keyframes heightAnim {
    to {
      height: 0;
    }
  }
  #search_results.show{
    height:auto;
  }
  .tree_ui_searchbar_wrapper:not(:hover) #search_results.show{
    animation: heightAnim 0.5s ease-out forwards;
    animation-delay: 3s;
  }
  .legend_items_wrapper:hover div, #search_results:hover > div > div{
    filter: contrast(0.35) brightness(1.45);
  }
  .legend_items_wrapper div:hover,#search_results > div > div:hover{
    filter: none;
  }
  .more_options{
   transform: translateX(-30px);
   opacity: 0;
   display: none;
   width: 103%;
  }
  .more_options_close{
   right: 20px;
   top: 20px;
  }

  @media screen and (max-width:640px) {
    .tree_ui_searchbar {
      max-width: none;
    }
    .padding_ui {
    padding: 140px 20px 20px 20px;
    }
  }
</style>
