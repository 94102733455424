<template>
  <div
    class="relative flex items-center justify-center min-h-100vh w-full p-7 login-background"
  >
    <img
      src="@/assets/img/frame.png"
      alt=""
      class="absolute right-0 bottom-0"
    />
    <div class="p-6 w-full max-w-840 z-10 relative">
      <div
        class="p-6 bg-white rounded shadow-md text-center px-32 py-12 mx900:px-16 mx640:px-6 mx640:py-5"
      >
        <div class=" mb-10">
          <img src="@/assets/img/mra_logo.png" alt="" class="mx-auto" />
        </div>
        <div class="mb-8">
          <h1 class="mx640:text-3xl">{{ loggedout_title }}</h1>
        </div>
        <div
          class="text-2xl mb-10 leading-35 mx640:text-base mx640:leading-26"
          v-html="loggedout_text"
        ></div>
        <div class="mb-6">
          <a
            class="py-2 px-12 text-galaxy rounded inline-block border-2 border-galaxy hover:bg-galaxy hover:text-white transition duration-300 ease-in-out "
            href="https://smartmobilitymra.nl/" target="_blank"
          >
            Naar Platfom Smart Mobility
          </a>
        </div>
        <a
          href=""
          class="text-galaxy cursor-pointer underline mx640:text-base hover:text-poppy transition duration-300 ease-in-out"
          @click="goToLogin"
        >
          opnieuw inloggen
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/modules/api";
  import { globals } from "@/modules/globals";
  import { EventBus } from "@/modules/event_bus";

  export default {
    name: "LoggedOut",
    props: [],
    data() {
      return {
        loggedout_title:
          globals.api_data.fetch_tree.texts["uitgelogd-scherm"].title,
        loggedout_text:
          globals.api_data.fetch_tree.texts["uitgelogd-scherm"].text,
      };
    },
    mounted() {
      api.call({
        task: "logout",
        event: "data_logout",
        global: false,
      });
    },
    methods: {
      goToLogin() {
        EventBus.$emit("goToLogin");
      },
    },
  };
</script>

<style></style>
