<template>
  <div class="relative flex items-center justify-center min-h-100vh w-full p-7 bg-gray-400 login-background">
    <img
      src="@/assets/img/frame.png"
      alt=""
      class="absolute right-0 bottom-0"
    />
    <div class="p-6 w-full max-w-840 z-10 relative">
      <div class="bg-white rounded shadow-md text-center px-32 py-12 mx900:px-16 mx640:px-6 mx640:py-5"> 

          <h1 class="mb-4 mx640:text-3xl">{{wachtwoord_vergeten_title}}</h1>

          <div v-show="!request_sent">
            <div class="text-2xl mb-10 leading-35 mx640:text-base mx640:leading-26" v-html="wachtwoord_vergeten_text">
            </div>
            <div class="text-left">
              <div class="mb-2">
                <label for="email" class="uppercase text-xs">
                  email adres
                </label>
              </div>
              <div class="mb-4">
                <input class="px-4 py-2 rounded border-2 border-galaxy w-full" id="email" ref="email" v-model="email" type="email">
              </div>
              <div class="mt-6 rounded border border-poppy p-6 text-poppy mx640:text-base mx640:p-3" v-show="error_show">
                {{error_message}}
              </div>
              <div class="pb-6"></div>
            </div>
            <div class="relative">
              <button type="button" class="py-2 px-12 text-galaxy text-19 rounded inline-block border-2 border-galaxy hover:bg-galaxy hover:text-white transition duration-300 ease-in-out" @click="send">verstuur</button>
              <div class="pos-g bg-white bg-opacity-50" v-show="click_disabled"></div>
            </div>
          </div> 

          <div v-show="request_sent">
            <div class="text-2xl mb-10 leading-35 mx640:text-base mx640:leading-26" v-html="wachtwoord_vergeten_text_sent">
            </div>
          </div>   

      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api'
import { globals } from "@/modules/globals";
import { EventBus } from '@/modules/event_bus'

var formIssues = {
  'email_not_found': {
    field: 'email',
    message: 'E-mail niet gevonden'
  },
  'not_verified': {
    field: 'email',
    message: 'Dit emailadres is nog niet geactiveerd. Activeer eerst je account door op de activatielink in de aanmeldingsmail te klikken.'
  }
}

export default {
  name: "PasswordResetRequest",
  props: [],
  data() {
    return {
      wachtwoord_vergeten_title: globals.api_data.fetch_intro['wachtwoord-vergeten'].title,
      wachtwoord_vergeten_text: globals.api_data.fetch_intro['wachtwoord-vergeten'].text,
      wachtwoord_vergeten_text_sent: 'Er is een email gestuurd naar het opgegeven adres waarmee een nieuw wachtwoord kan worden ingesteld.',
      click_disabled: false,
      email: '',
      error_message: '',
      error_show: false,
      request_sent: false
    }
  },
  mounted() {

    EventBus.$on('data_password_reset_request', (e) => {
      this.dataLoaded(e)
    })    
  },
  methods: {
    send(){
      this.click_disabled = true

      var formData = {
        email: this.email
      }
      api.call({
        task: 'password_reset_request',
        event: 'data_password_reset_request',
        global: false,
        formData: formData
      })
    },
    dataLoaded(e){
      console.log(e)

      if(e.result == 'nok'){
        this.formError(e.data.respond)
        return
      }   
      
      console.log('password_reset_request success')
      this.request_sent = true

    },
    formError(e){
      this.error_message = formIssues[e].message
      this.error_show = true
      this.click_disabled = false
    }
  },
}
</script>

<style>

</style>

